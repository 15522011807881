import React from 'react';

import { useErrorBoundaryHook } from './error.hook';
import { ErrorBoundaryLayout } from './error.layout';

interface IErrorBoundary {
  children: React.ReactNode;
}

export const ErrorBoundary = ({ children }: IErrorBoundary) => {
  const properties = useErrorBoundaryHook();

  return <ErrorBoundaryLayout {...properties} children={children} />;
};
