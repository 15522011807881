import Papa from 'papaparse';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ErrorsEnum, SplitModeEnum } from '~/api/constants';
import {
  useEmployerServiceEmployerControllerGetEmployerAccountBalance,
  useLoadOrderServiceOrderControllerCreateOrder,
} from '~/api/queries';
import {
  ApiError,
  CreateOrderBodyDto,
  OrderDetails,
  SplitModeEnum as SplitModeEnumType,
} from '~/api/requests';
import { setError as setErrorRedux } from '~/redux/reducers/application';

import { OrderBalancesEnum } from '../recharge-details/types';
import { RechargeSteps } from '../types';
import { DistributionOptions } from './distribution/types';
import { PaymentOptions, PaymentSelectedOptions } from './payment-type/types';
import { CreateOrderErrorMessageEnum } from './send/send.hook';
import { NewRechargeSteps } from './types';

interface INewRecharge {
  currentStep: NewRechargeSteps;
  onChangeStep: (step: NewRechargeSteps) => void;
  file: File | null;
  onChangeFile: (file: File) => void;
  fileData: OrderDetails;
  onChangeFileData: (data: OrderDetails) => void;
  stepHasError: boolean;
  onSetStepHasError: (hasError: boolean) => void;
  fileErrors: FileErrors;
  onChangeFileErrors: (errors: FileErrors) => void;
  paymentTypes: PaymentSelectedOptions;
  onChangePaymentTypes: (value: PaymentOptions) => void;
  totalValue: number;
  creditBalance: number;
  isBalanceLoading: boolean;
  tedValue: number;
  onChangeTedValue: (value: number) => void;
  handleCreateOrder: () => Promise<void>;
  isOrderLoading: boolean;
  creationSuccess: boolean;
  distributionType: DistributionOptions | null;
  onChangeDistributionType: (type: DistributionOptions) => void;
  selectedDate: Date | null;
  onChangeSelectedDate: (date: Date | null) => void;
}

interface IUseNewRecharge {
  onChangeRechargeStep: (step: RechargeSteps) => void;
}

export type FileErrors = {
  line: number;
  column: string;
  message: CreateOrderErrorMessageEnum;
}[];

export const useNewRecharge = ({ onChangeRechargeStep }: IUseNewRecharge): INewRecharge => {
  const dispatch = useDispatch();

  const INITIAL_STATE = {
    ted: false,
    pix: false,
    billet: false,
    credits: false,
  };

  const [currentStep, setCurrentStep] = useState<NewRechargeSteps>(NewRechargeSteps.SEND);
  const [file, setFile] = useState<File | null>(null);
  const [fileData, setFileData] = useState<OrderDetails>({
    totalEmployees: 0,
    totalBalance: 0,
    totalItems: 0,
    balances: [],
  });
  const [fileErrors, setFileErrors] = useState<FileErrors>([]);
  const [stepHasError, setStepHasError] = useState(false);
  const [paymentTypes, setPaymentTypes] = useState<PaymentSelectedOptions>(INITIAL_STATE);
  const [tedValue, setTedValue] = useState(0);
  const [distributionType, setDistributionType] = useState<DistributionOptions | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const totalValue = Number(fileData.totalBalance);

  const getSplitMode = (): SplitModeEnumType => {
    if (
      distributionType === DistributionOptions.INSTANT_WEEKDAY ||
      distributionType === DistributionOptions.INSTANT_WEEKEND
    ) {
      return SplitModeEnum.INSTANTANEOUS;
    }
    if (distributionType === DistributionOptions.SCHEDULE) {
      return SplitModeEnum.SCHEDULED;
    }
    return SplitModeEnum.MANUAL;
  };

  const getScheduledDate = (): string | undefined => {
    if (distributionType === DistributionOptions.SCHEDULE && selectedDate) {
      return selectedDate.toISOString();
    }
    return undefined;
  };

  const getBalanceIndex = (index: number) => {
    const balanceIndexMap: { [key: number]: OrderBalancesEnum } = {
      1: OrderBalancesEnum.foodMealBalance,
      2: OrderBalancesEnum.foodBalance,
      3: OrderBalancesEnum.mealBalance,
      4: OrderBalancesEnum.cultureBalance,
      5: OrderBalancesEnum.fuelBalance,
      6: OrderBalancesEnum.transportBalance,
      7: OrderBalancesEnum.mobilityBalance,
      8: OrderBalancesEnum.travelBalance,
      9: OrderBalancesEnum.recreationBalance,
      10: OrderBalancesEnum.educationBalance,
      11: OrderBalancesEnum.healthBalance,
      12: OrderBalancesEnum.homeOfficeBalance,
      13: OrderBalancesEnum.generalBalance,
    };

    return balanceIndexMap[index] || OrderBalancesEnum.generalBalance;
  };

  const updateFileData = (file: File) => {
    const newData: OrderDetails = {
      totalEmployees: 0,
      totalBalance: 0,
      totalItems: 0,
      balances: [],
    };

    const updateBalances = (row: string[]) => {
      if (row.length > 1) {
        newData.totalEmployees += 1;

        for (let i = 1; i < row.length; i++) {
          if (row.length > 1) {
            const balanceType = getBalanceIndex(i);
            const amount = parseFloat(row[i]) || 0;
            let balance = newData.balances.find((b) => b.type === balanceType);
            if (!balance) {
              balance = { type: balanceType, title: balanceType, amount: 0 };
              newData.balances.push(balance);
            }
            balance.amount += amount;
            newData.totalBalance += amount;
          }
        }
      }
    };

    Papa.parse(file, {
      delimiter: ',',
      complete: (results) => {
        const fileMatrix = results.data as string[][];
        fileMatrix.slice(1).forEach(updateBalances);
      },
    });
  };

  const onChangeStep = (step: NewRechargeSteps) => {
    if (step === NewRechargeSteps.PAYMENT && tedValue === 0) {
      onChangeRechargeStep(RechargeSteps.BALANCE);
      return;
    }
    setCurrentStep(step);
  };

  const onChangeFile = (file: File) => {
    setFile(file);
  };

  const onChangeFileErrors = (errors: FileErrors) => {
    setFileErrors(errors);
  };

  const onChangePaymentTypes = (value: PaymentOptions) => {
    if (creditBalance >= totalValue) {
      if (value === PaymentOptions.CREDITS) {
        setPaymentTypes({
          credits: true,
          pix: false,
          billet: false,
          ted: false,
        });
      }
      if (value === PaymentOptions.TED) {
        setPaymentTypes({
          credits: false,
          pix: false,
          billet: false,
          ted: true,
        });
      }
    } else {
      setPaymentTypes((prevState) => ({
        ...prevState,
        [value]: !prevState[value],
      }));
    }
  };

  const onChangeDistributionType = (type: DistributionOptions) => {
    setDistributionType(type);
  };

  const onChangeSelectedDate = (date: Date | null) => {
    setSelectedDate(date);
  };

  const getCreditBalance = useEmployerServiceEmployerControllerGetEmployerAccountBalance();

  const creditBalance = getCreditBalance.data?.balance ?? 0;

  const createOrder = useLoadOrderServiceOrderControllerCreateOrder({
    onSuccess: () => {
      updateFileData(file as File);
      onChangeStep(NewRechargeSteps.PAYMENT);
    },
    onError: async (e: ApiError) => {
      const errorCode = await e.body.code;
      if (errorCode === ErrorsEnum.LOAD_ORDER_INVALID_VALUES) {
        const errors: FileErrors = await e.body.details;
        onChangeFileErrors(errors);
        return;
      }
      dispatch(setErrorRedux(errorCode));
      createOrder.reset();
    },
  });

  const handleCreateOrder = async (): Promise<void> => {
    createOrder.mutate({
      formData: { file: file } as unknown as CreateOrderBodyDto,
      splitMode: getSplitMode(),
      splitScheduleDate: getScheduledDate(),
    });
  };

  useEffect(() => {
    if (fileErrors.length > 0) {
      setStepHasError(true);
    }
  }, [fileErrors]);

  useEffect(() => {
    if (currentStep === NewRechargeSteps.SEND) {
      setStepHasError(false);
    }
  }, [currentStep]);

  return {
    file,
    onChangeFile,
    fileData,
    onChangeFileData: setFileData,
    currentStep,
    stepHasError,
    onChangeStep,
    onSetStepHasError: setStepHasError,
    fileErrors,
    onChangeFileErrors,
    paymentTypes,
    onChangePaymentTypes,
    totalValue,
    creditBalance,
    isBalanceLoading: getCreditBalance.isPending,
    tedValue,
    onChangeTedValue: setTedValue,
    handleCreateOrder,
    isOrderLoading: createOrder.isPending,
    creationSuccess: createOrder.isSuccess,
    distributionType,
    onChangeDistributionType,
    selectedDate,
    onChangeSelectedDate,
  };
};
