import { ReactComponent as DSAdd } from '@vizir-banking/design-system/src/assets/icons/svgs/add.svg';
import { ReactComponent as DSAddCircle } from '@vizir-banking/design-system/src/assets/icons/svgs/add-circle.svg';
import { ReactComponent as DSAnticipation } from '@vizir-banking/design-system/src/assets/icons/svgs/anticipation.svg';
import { ReactComponent as DSApproveCircle } from '@vizir-banking/design-system/src/assets/icons/svgs/approve-circle.svg';
import { ReactComponent as DSBarcode } from '@vizir-banking/design-system/src/assets/icons/svgs/barcode.svg';
import { ReactComponent as DSBell } from '@vizir-banking/design-system/src/assets/icons/svgs/bell.svg';
import { ReactComponent as DSBill } from '@vizir-banking/design-system/src/assets/icons/svgs/bill.svg';
import { ReactComponent as DSBus } from '@vizir-banking/design-system/src/assets/icons/svgs/bus.svg';
import { ReactComponent as DSCalendar } from '@vizir-banking/design-system/src/assets/icons/svgs/calendar.svg';
import { ReactComponent as DSCardBill } from '@vizir-banking/design-system/src/assets/icons/svgs/card-bill.svg';
import { ReactComponent as DSCashIn } from '@vizir-banking/design-system/src/assets/icons/svgs/cash-in.svg';
import { ReactComponent as DSCashOut } from '@vizir-banking/design-system/src/assets/icons/svgs/cash-out.svg';
import { ReactComponent as DSCellphone } from '@vizir-banking/design-system/src/assets/icons/svgs/cellphone.svg';
import { ReactComponent as DSChat } from '@vizir-banking/design-system/src/assets/icons/svgs/chat.svg';
import { ReactComponent as DSCheck } from '@vizir-banking/design-system/src/assets/icons/svgs/check.svg';
import { ReactComponent as DSCheckCircle } from '@vizir-banking/design-system/src/assets/icons/svgs/check-circle.svg';
import { ReactComponent as DSChevronDown } from '@vizir-banking/design-system/src/assets/icons/svgs/chevron-down.svg';
import { ReactComponent as DSChevronLeft } from '@vizir-banking/design-system/src/assets/icons/svgs/chevron-left.svg';
import { ReactComponent as DSChevronRight } from '@vizir-banking/design-system/src/assets/icons/svgs/chevron-right.svg';
import { ReactComponent as DSChevronUp } from '@vizir-banking/design-system/src/assets/icons/svgs/chevron-up.svg';
import { ReactComponent as DSChevronsLeft } from '@vizir-banking/design-system/src/assets/icons/svgs/chevrons-left.svg';
import { ReactComponent as DSChevronsRight } from '@vizir-banking/design-system/src/assets/icons/svgs/chevrons-right.svg';
import { ReactComponent as DSClearInput } from '@vizir-banking/design-system/src/assets/icons/svgs/clear-input.svg';
import { ReactComponent as DSClose } from '@vizir-banking/design-system/src/assets/icons/svgs/close.svg';
import { ReactComponent as DSCoin } from '@vizir-banking/design-system/src/assets/icons/svgs/coin.svg';
import { ReactComponent as DSCompanyBuilding } from '@vizir-banking/design-system/src/assets/icons/svgs/company-building.svg';
import { ReactComponent as DSCopy } from '@vizir-banking/design-system/src/assets/icons/svgs/copy.svg';
import { ReactComponent as DSCreditCard } from '@vizir-banking/design-system/src/assets/icons/svgs/credit-card.svg';
import { ReactComponent as DSDownload } from '@vizir-banking/design-system/src/assets/icons/svgs/download.svg';
import { ReactComponent as DSEdit } from '@vizir-banking/design-system/src/assets/icons/svgs/edit.svg';
import { ReactComponent as DSEmail } from '@vizir-banking/design-system/src/assets/icons/svgs/email.svg';
import { ReactComponent as DSErrorCircle } from '@vizir-banking/design-system/src/assets/icons/svgs/error-circle.svg';
import { ReactComponent as DSExclamationCircle } from '@vizir-banking/design-system/src/assets/icons/svgs/exclamation-circle.svg';
import { ReactComponent as DSEye } from '@vizir-banking/design-system/src/assets/icons/svgs/eye.svg';
import { ReactComponent as DSEyeSlash } from '@vizir-banking/design-system/src/assets/icons/svgs/eye-slash.svg';
import { ReactComponent as DSFaq } from '@vizir-banking/design-system/src/assets/icons/svgs/faq.svg';
import { ReactComponent as DSFile } from '@vizir-banking/design-system/src/assets/icons/svgs/file.svg';
import { ReactComponent as DSFilter } from '@vizir-banking/design-system/src/assets/icons/svgs/filter.svg';
import { ReactComponent as DSFlash } from '@vizir-banking/design-system/src/assets/icons/svgs/flash.svg';
import { ReactComponent as DSHelpCircle } from '@vizir-banking/design-system/src/assets/icons/svgs/help-circle.svg';
import { ReactComponent as DSHouse } from '@vizir-banking/design-system/src/assets/icons/svgs/house.svg';
import { ReactComponent as DSInfoCircle } from '@vizir-banking/design-system/src/assets/icons/svgs/info-circle.svg';
import { ReactComponent as DSLeftArrow } from '@vizir-banking/design-system/src/assets/icons/svgs/left-arrow.svg';
import { ReactComponent as DSLink } from '@vizir-banking/design-system/src/assets/icons/svgs/link.svg';
import { ReactComponent as DSLock } from '@vizir-banking/design-system/src/assets/icons/svgs/lock.svg';
import { ReactComponent as DSLogout } from '@vizir-banking/design-system/src/assets/icons/svgs/logout.svg';
import { ReactComponent as DSMenu } from '@vizir-banking/design-system/src/assets/icons/svgs/menu.svg';
import { ReactComponent as DSMenuHorizontal } from '@vizir-banking/design-system/src/assets/icons/svgs/menu-horizontal.svg';
import { ReactComponent as DSMenuVertical } from '@vizir-banking/design-system/src/assets/icons/svgs/menu-vertical.svg';
import { ReactComponent as DSMinus } from '@vizir-banking/design-system/src/assets/icons/svgs/minus.svg';
import { ReactComponent as DSMinusCircle } from '@vizir-banking/design-system/src/assets/icons/svgs/minus-circle.svg';
import { ReactComponent as DSMobile } from '@vizir-banking/design-system/src/assets/icons/svgs/mobile.svg';
import { ReactComponent as DSPieChart } from '@vizir-banking/design-system/src/assets/icons/svgs/pie-chart.svg';
import { ReactComponent as DSPix } from '@vizir-banking/design-system/src/assets/icons/svgs/pix.svg';
import { ReactComponent as DSProgressIndicator } from '@vizir-banking/design-system/src/assets/icons/svgs/progress-indicator.svg';
import { ReactComponent as DSReload } from '@vizir-banking/design-system/src/assets/icons/svgs/reload.svg';
import { ReactComponent as DSSearch } from '@vizir-banking/design-system/src/assets/icons/svgs/search.svg';
import { ReactComponent as DSSelfie } from '@vizir-banking/design-system/src/assets/icons/svgs/selfie.svg';
import { ReactComponent as DSSettings } from '@vizir-banking/design-system/src/assets/icons/svgs/settings.svg';
import { ReactComponent as DSShare } from '@vizir-banking/design-system/src/assets/icons/svgs/share.svg';
import { ReactComponent as DSTicket } from '@vizir-banking/design-system/src/assets/icons/svgs/ticket.svg';
import { ReactComponent as DSTransfer } from '@vizir-banking/design-system/src/assets/icons/svgs/transfer.svg';
import { ReactComponent as DSTrash } from '@vizir-banking/design-system/src/assets/icons/svgs/trash.svg';
import { ReactComponent as DSUnlock } from '@vizir-banking/design-system/src/assets/icons/svgs/unlock.svg';
import { ReactComponent as DSUpload } from '@vizir-banking/design-system/src/assets/icons/svgs/upload.svg';
import { ReactComponent as DSUser } from '@vizir-banking/design-system/src/assets/icons/svgs/user.svg';
import { ReactComponent as DSUserApprove } from '@vizir-banking/design-system/src/assets/icons/svgs/user-approve.svg';
import { ReactComponent as DSVirtualCard } from '@vizir-banking/design-system/src/assets/icons/svgs/virtual-card.svg';
import { ReactComponent as DSWithdrawal } from '@vizir-banking/design-system/src/assets/icons/svgs/withdrawal.svg';
import { IconographyNames } from '@vizir-banking/design-system/src/enums/iconography-names';
import { Iconography } from '@vizir-banking/design-system/src/iconography/iconography-types';

export const designSystemIcons: Iconography = {
  check: DSCheck,
  checkCircle: DSCheckCircle,
  clearInput: DSClearInput,
  eye: DSEye,
  eyeSlash: DSEyeSlash,
  infoCircle: DSInfoCircle,
  minus: DSMinus,
  [IconographyNames.addCircle]: DSAddCircle,
  [IconographyNames.add]: DSAdd,
  [IconographyNames.cellphone]: DSCellphone,
  [IconographyNames.barcode]: DSBarcode,
  [IconographyNames.bell]: DSBell,
  [IconographyNames.bill]: DSBill,
  [IconographyNames.bus]: DSBus,
  [IconographyNames.calendar]: DSCalendar,
  [IconographyNames.chat]: DSChat,
  [IconographyNames.chevronDown]: DSChevronDown,
  [IconographyNames.chevronLeft]: DSChevronLeft,
  [IconographyNames.chevronRight]: DSChevronRight,
  [IconographyNames.chevronUp]: DSChevronUp,
  [IconographyNames.close]: DSClose,
  [IconographyNames.coin]: DSCoin,
  [IconographyNames.copy]: DSCopy,
  [IconographyNames.creditCard]: DSCreditCard,
  [IconographyNames.download]: DSDownload,
  [IconographyNames.edit]: DSEdit,
  [IconographyNames.email]: DSEmail,
  [IconographyNames.errorCircle]: DSErrorCircle,
  [IconographyNames.faq]: DSFaq,
  [IconographyNames.filter]: DSFilter,
  [IconographyNames.helpCircle]: DSHelpCircle,
  [IconographyNames.leftArrow]: DSLeftArrow,
  [IconographyNames.link]: DSLink,
  [IconographyNames.lock]: DSLock,
  [IconographyNames.logout]: DSLogout,
  [IconographyNames.menu]: DSMenu,
  [IconographyNames.menuVertical]: DSMenuVertical,
  [IconographyNames.minusCircle]: DSMinusCircle,
  [IconographyNames.mobile]: DSMobile,
  [IconographyNames.pieChart]: DSPieChart,
  [IconographyNames.reload]: DSReload,
  [IconographyNames.search]: DSSearch,
  [IconographyNames.selfie]: DSSelfie,
  [IconographyNames.settings]: DSSettings,
  [IconographyNames.share]: DSShare,
  [IconographyNames.ticket]: DSTicket,
  [IconographyNames.trash]: DSTrash,
  [IconographyNames.unlock]: DSUnlock,
  [IconographyNames.upload]: DSUpload,
  [IconographyNames.user]: DSUser,
  [IconographyNames.cashIn]: DSCashIn,
  [IconographyNames.cashOut]: DSCashOut,
  [IconographyNames.cardBill]: DSCardBill,
  [IconographyNames.transfer]: DSTransfer,
  [IconographyNames.virtualCard]: DSVirtualCard,
  [IconographyNames.pix]: DSPix,
  [IconographyNames.withdrawal]: DSWithdrawal,
  [IconographyNames.menuHorizontal]: DSMenuHorizontal,
  [IconographyNames.exclamationCircle]: DSExclamationCircle,
  progressIndicator: DSProgressIndicator,
  [IconographyNames.approveCircle]: DSApproveCircle,
  [IconographyNames.anticipation]: DSAnticipation,
  [IconographyNames.house]: DSHouse,
  [IconographyNames.file]: DSFile,
  [IconographyNames.chevronsLeft]: DSChevronsLeft,
  [IconographyNames.chevronsRight]: DSChevronsRight,
  [IconographyNames.companyBuilding]: DSCompanyBuilding,
  [IconographyNames.flash]: DSFlash,
  [IconographyNames.userApprove]: DSUserApprove,
};
