import { IconographyNames } from '@vizir-banking/design-system';

import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import { NewRechargeSteps } from '../types';
import {
  Banner,
  ButtonWrapper,
  Column,
  Dialog,
  ErrorIcon,
  FileContainer,
  FileWrapper,
  Icon,
  Link,
  Paragraph,
  PrimaryButton,
  Row,
} from './validation.styles';

interface IRechargeValidationLayout {
  file: File | null;
  handleValidateFile: () => void;
  isValidationLoading: boolean;
  onChangeNewRechargeStep: (step: NewRechargeSteps) => void;
}

export const RechargeValidationLayout = ({
  file,
  handleValidateFile,
  isValidationLoading,
  onChangeNewRechargeStep,
}: IRechargeValidationLayout) => {
  const translate = useTranslation('pages.rh.recharge.newRecharge.tabs.send');
  const getFileSize = (): string => {
    if (file)
      return file.size > 1_000_000
        ? `${(Number(file?.size) / 1_000_000).toFixed(2)}MB`
        : `${(Number(file?.size) / 1_000).toFixed(2)}KB`;
    return '0KB';
  };
  const renderFile = () => {
    return (
      <FragmentCol>
        <FileContainer>
          <FileWrapper>
            <Row>
              <Icon iconName={IconographyNames.file} />

              <Column>
                <Dialog>{file?.name}</Dialog>
                <Paragraph>{getFileSize()}</Paragraph>
              </Column>
            </Row>

            <Link onClick={() => onChangeNewRechargeStep(NewRechargeSteps.SEND)}>
              <ErrorIcon iconName={IconographyNames.trash} />
            </Link>
          </FileWrapper>
        </FileContainer>
        <Banner title={translate('banners.mayTakeAWhile')} />
        <Banner title={translate('banners.maxItems')} />
      </FragmentCol>
    );
  };
  const renderButtons = () => {
    return (
      <ButtonWrapper>
        <Link onClick={() => onChangeNewRechargeStep(NewRechargeSteps.SEND)}>
          {translate('buttons.cancel')}
        </Link>
        <PrimaryButton
          label={translate('buttons.validateOrder')}
          onClick={() => handleValidateFile()}
          trailingIconName={IconographyNames.chevronRight}
          withTrailingIcon
          isDisabled={!file}
          isLoading={isValidationLoading}
        />
      </ButtonWrapper>
    );
  };

  const renderContent = () => {
    return (
      <FragmentCol style={{ flex: 1 }}>
        {renderFile()}
        {renderButtons()}

        <ErrorDialog />
      </FragmentCol>
    );
  };

  return <>{renderContent()}</>;
};
