import Holidays from 'date-holidays';
import { useEffect, useState } from 'react';

import { DistributionOptions, months } from './types';

interface IRechargeDistribution {
  distributionType: DistributionOptions | null;
  onChangeDistributionType: (type: DistributionOptions) => void;
  totalValue: number;
  creditBalance: number;
  selectedDate: Date | null;
  onChangeSelectedDate: (date: Date | null) => void;
  monthPickerState: {
    pickMonthOpen: boolean;
    visibleMonth: string;
  };
  onChangeMonthPickerState: (state: { pickMonthOpen?: boolean; visibleMonth?: string }) => void;
  isValidTedDate: (date: Date) => boolean;
}

interface IUseRechargeDistribution {
  totalValue: number;
  creditBalance: number;
  distributionType: DistributionOptions | null;
  onChangeDistributionType: (type: DistributionOptions) => void;
  selectedDate: Date | null;
  onChangeSelectedDate: (date: Date | null) => void;
}

enum Week {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export const useRechargeDistribution = ({
  totalValue,
  creditBalance,
  distributionType,
  onChangeDistributionType,
  selectedDate,
  onChangeSelectedDate,
}: IUseRechargeDistribution): IRechargeDistribution => {
  const hd = new Holidays('BR');

  useEffect(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const firstAvailableDate = getNextUtilDay(tomorrow);
    onChangeSelectedDate(firstAvailableDate);
  }, []);

  const isHoliday = (date: Date): boolean => {
    const holiday = hd.isHoliday(date);
    if (holiday instanceof Array) {
      holiday.filter((h) => h.type === 'public');
    }
    return Boolean(holiday);
  };
  const isWeekend = (date: Date): boolean => {
    return [Week.SATURDAY, Week.SUNDAY].includes(date.getDay());
  };

  const getNextUtilDay = (date: Date): Date => {
    if (isWeekend(date) || isHoliday(date)) {
      return getNextUtilDay(new Date(date.setDate(date.getDate() + 1)));
    }
    return date;
  };

  const [monthPickerState, setMonthPickerState] = useState({
    pickMonthOpen: false,
    visibleMonth: months[new Date().getMonth()],
  });

  const onChangeMonthPickerState = (state: { pickMonthOpen?: boolean; visibleMonth?: string }) => {
    setMonthPickerState((prevState) => ({
      ...prevState,
      ...state,
    }));
  };

  const isValidTedDate = (date: Date): boolean => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    tomorrow.setHours(3, 0, 0, 0);
    const nextUtil = getNextUtilDay(new Date(tomorrow));

    tomorrow.setHours(0, 0, 0, 0);
    nextUtil.setHours(0, 0, 0, 0);

    if (date < tomorrow || date < nextUtil) {
      return false;
    }

    return true;
  };
  return {
    distributionType,
    onChangeDistributionType,
    totalValue,
    creditBalance,
    selectedDate,
    onChangeSelectedDate,
    monthPickerState,
    onChangeMonthPickerState,
    isValidTedDate,
  };
};
