export enum DistributionOptions {
  SCHEDULE = 'schedule',
  INSTANT_WEEKDAY = 'instantWeekDay',
  INSTANT_WEEKEND = 'instantWeekend',
  MANUAL = 'manual',
}

export const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];
