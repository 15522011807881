import Papa from 'papaparse';
import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';

import { identifyDelimiter } from '~/utils/identify-delimiter';

import { CreateBatchProposalErrorMessageEnum } from '../send/send';
import { BatchProposalColumns } from './types';

interface IBatchProposalReview {
  file: File | null;
  fileErrors: { line: number; column: string; message: CreateBatchProposalErrorMessageEnum }[];
}

interface IBatchProposalReviewReturn {
  validateData: (columnIndex: number, rowIndex: number) => boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[][];
}

export const useBatchProposalReview = ({
  file,
  fileErrors,
}: IBatchProposalReview): IBatchProposalReviewReturn => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any[][]>([]);

  useEffect(() => {
    if (file) {
      const readFile = async () => {
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        const fileText = await file.text();
        if (fileExtension === 'xlsx') {
          readXLSX();
        } else if (fileExtension === 'csv' || fileExtension === 'tsv') {
          readCSVOrTSV(file, fileExtension === 'csv' ? identifyDelimiter(fileText) : '\t');
        }
      };
      readFile();
    }
  }, [file]);

  const readXLSX = () => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryStr = event.target?.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      // @ts-expect-error - The type of jsonData is any[][]
      setData(jsonData);
    };
  };

  const readCSVOrTSV = (file: File, delimiter: string) => {
    Papa.parse(file, {
      delimiter,
      complete: (results) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setData(results.data as any[][]);
      },
    });
  };

  const getColumnName = (index: number): string => {
    const columnMap: { [key: number]: BatchProposalColumns } = {
      0: BatchProposalColumns.PERSON_FULL_NAME,
      1: BatchProposalColumns.PREFERRED_NAME,
      2: BatchProposalColumns.GENDER,
      3: BatchProposalColumns.MARITAL_STATUS,
      4: BatchProposalColumns.EMANCIPATED_MINOR,
      5: BatchProposalColumns.EMAIL,
      6: BatchProposalColumns.CPF,
      7: BatchProposalColumns.MOTHER_NAME,
      8: BatchProposalColumns.BIRTH_DATE,
      9: BatchProposalColumns.PHONE_DIALING_CODE,
      10: BatchProposalColumns.PHONE_AREA_CODE,
      11: BatchProposalColumns.PHONE_NUMBER,
      12: BatchProposalColumns.ADDRESS_POSTAL_CODE,
      13: BatchProposalColumns.ADDRESS_STREET,
      14: BatchProposalColumns.ADDRESS_NUMBER,
      15: BatchProposalColumns.ADDRESS_COMPLEMENT,
      16: BatchProposalColumns.ADDRESS_NEIGHBORHOOD,
      17: BatchProposalColumns.ADDRESS_CITY,
      18: BatchProposalColumns.ADDRESS_ADMINISTRATIVE_AREA_CODE,
      19: BatchProposalColumns.ADDRESS_COUNTRY_CODE,
    };
    return columnMap[index];
  };

  const validateData = (columnIndex: number, rowIndex: number): boolean => {
    let isValid = true;
    fileErrors.map((error) => {
      if (error.column === getColumnName(columnIndex) && error.line === rowIndex + 1) {
        isValid = false;
        return;
      }
    });

    return isValid;
  };

  return { validateData, data };
};
