import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { ApiError, OpenAPI } from '~/api/requests';
import { Interceptors } from '~/api/requests/core/OpenAPI';
import { env } from '~/config/env';
import { useTokenExpirationContext } from '~/providers/token-expiration-provider';
import { useVerificationCodeContext } from '~/providers/verification-code-provider';
import { ApiRequestOptions } from '../requests/core/ApiRequestOptions';
import { ApiResult } from '../requests/core/ApiResult';
import { ErrorsEnum } from '~/translates/error/types';
import { selectToken } from '~/redux/reducers/user';

type Props = {
  children: ReactElement;
};

export function GlobalInterceptor({ children }: Props) {
  const { handleTokenExpiration } = useTokenExpirationContext();
  const { showDialog } = useVerificationCodeContext();

  const interceptor = new Interceptors<AxiosResponse>();

  const token = useSelector(selectToken);

  interceptor.use(async (response: AxiosResponse) => {
    const validationId = response.data.validationId;

    if (validationId && response.status === 401) {
      const originalRequest = response.config as AxiosRequestConfig;
      const email = String(originalRequest.headers?.['x-document'] ?? '');
      const path = originalRequest.url?.replace(env.REACT_APP_BASE_URL, '') ?? '';

      const showDialogResponse = await showDialog(email, '/' + path);

      if (!showDialogResponse?.code) {
        const apiError = new ApiError(
          originalRequest as ApiRequestOptions,
          {
            body: {
              code: ErrorsEnum.CANCELED_REQUEST,
            },
          } as ApiResult,
          'Chamada interrompida'
        );
        return Promise.reject(apiError);
      }

      if (originalRequest.headers) {
        originalRequest.headers['x-validation-id'] = showDialogResponse?.validationId ?? validationId;
        originalRequest.headers['x-verification-code'] = showDialogResponse?.code;
      }

      const api = axios.create();

      try {
        return await api(originalRequest);
      } catch (error: any) {
        if (error.response) {
          return {
            ...response,
            data: error.response?.data ?? {},
          };
        }
        return Promise.reject(error);
      }
    }

    return response;
  });

  interceptor.use((response: AxiosResponse) => {
    const code = response.data.code;

    if (code === ErrorsEnum.EXPIRED_TOKEN && response.status === 401) {
      handleTokenExpiration();
    }

    return response;
  });

  OpenAPI.interceptors = {
    request: new Interceptors(),
    response: interceptor,
  };

  if (token) {
    OpenAPI.HEADERS = {
      ...OpenAPI.HEADERS,
      Authorization: `Bearer ${token}`,
    };
  }

  return children;
}
