import { useEffect, useState } from 'react';

interface IErrorBoundary {
  hasError: boolean;
  onPress: () => Promise<void>;
}

export const useErrorBoundaryHook = (): IErrorBoundary => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleError = () => {
      setHasError(true);
    };

    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);

  const onPress = async () => {
    localStorage.clear();
    sessionStorage.clear();

    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    });

    window.location.href = '/';
  };

  return { hasError, onPress };
};
