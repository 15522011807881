import { formatToBRL } from 'brazilian-values';
import React from 'react';

import { EmployerControllerGetAccountThatWillReceivePaymentsResponse } from '~/api/requests';
import { PaymentDetails } from '~/components/payment-details/payment-details';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

interface IRechargePaymentLayout {
  info?: EmployerControllerGetAccountThatWillReceivePaymentsResponse;
  isLoading: boolean;
  goBack: () => void;
  totalValue: number;
}

export const RechargePaymentLayout = ({ info, goBack, isLoading, totalValue }: IRechargePaymentLayout) => {
  const translate = useTranslation('pages.rh.recharge.newRecharge.tabs.payment');
  return (
    <FragmentCol>
      <PaymentDetails
        goBack={goBack}
        goBackText={translate('goBack')}
        isLoading={isLoading}
        totalBalance={formatToBRL(totalValue)}
        info={info}
      />
    </FragmentCol>
  );
};
