import { useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, DragEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useBatchProposalServiceBatchProposalControllerCreateBatchProposal } from '~/api/queries';
import { ApiError, CreateBatchProposalRequestDto } from '~/api/requests';
import { RH_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/rh-portal';
import { clearSuccess, setError, setSuccess } from '~/redux/reducers/application';
import { ErrorsEnum } from '~/translates/error/types';
import { SuccessEnum } from '~/translates/success/types';

import { CreateBatchProposalErrorMessageEnum } from './send/send';

export type BatchCollaboratorsProps = {
  onFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onDrop: (event: DragEvent<HTMLFormElement>) => void;
  step: BatchCollaboratorsSteps;
  file: File | null;
  onDeleteFile: () => void;
  isLoading: boolean;
  onSubmitFile: () => Promise<void>;
  onSuccess: () => void;
  fileErrors: FileErrors;
  onChangeBatchProposalStep: (step: BatchCollaboratorsSteps) => void;
};

export enum BatchCollaboratorsSteps {
  SELECT_FILE = 'SELECT_FILE',
  REVIEW = 'REVIEW',
  SEND = 'SEND',
}

export type FileErrors = {
  line: number;
  column: string;
  message: CreateBatchProposalErrorMessageEnum;
}[];

export const useBatchCollaborators = (): BatchCollaboratorsProps => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [file, setFile] = useState<File>();
  const [step, setStep] = useState(BatchCollaboratorsSteps.SELECT_FILE);
  const [fileErrors, setFileErrors] = useState<FileErrors>([]);

  const batchProposal = useBatchProposalServiceBatchProposalControllerCreateBatchProposal({
    onSuccess: () => dispatch(setSuccess(SuccessEnum.BATCH_PROPOSAL_UPLOADED)),
    onError: (error: ApiError) => {
      if (error.body.code === ErrorsEnum.LOAD_ORDER_INVALID_VALUES) {
        setFileErrors(error.body.details);
        setStep(BatchCollaboratorsSteps.REVIEW);
      } else {
        dispatch(setError(error.body.code));
      }
    },
  });

  const onChangeBatchProposalStep = (step: BatchCollaboratorsSteps) => {
    setStep(step);
  };

  const onFileChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) validateFile(selectedFile);
  }, []);

  const onDrop = useCallback((event: DragEvent<HTMLFormElement>) => {
    event.preventDefault();
    const selectedFile = event.dataTransfer.files?.[0];

    if (selectedFile) validateFile(selectedFile);
  }, []);

  const validateFile = (file: File) => {
    const validExtensions = ['csv', 'txt'];
    const fileExtension = file.name.split('.').pop();

    if (fileExtension && validExtensions.includes(fileExtension)) {
      setFile(file);
      setStep(BatchCollaboratorsSteps.SEND);
      return;
    }

    dispatch(setError(ErrorsEnum.INVALID_FILE));
  };

  const onDeleteFile = () => {
    if (batchProposal.isPending) return;

    setFile(undefined);
    setStep(BatchCollaboratorsSteps.SELECT_FILE);
  };

  const onSubmitFile = async () => {
    batchProposal.mutate({
      formData: {
        file: file,
      } as unknown as CreateBatchProposalRequestDto,
    });
  };

  const onSuccess = () => {
    dispatch(clearSuccess());

    queryClient.invalidateQueries();
    navigate(RH_SCREENS_DEFINITIONS.listBatches);
  };

  return {
    onFileChange,
    onDrop,
    step,
    file: file ?? null,
    onDeleteFile,
    isLoading: batchProposal.isPending,
    onSubmitFile,
    onSuccess,
    fileErrors,
    onChangeBatchProposalStep,
  };
};
