import { ReactElement } from 'react';

import { ErrorBoundary } from '~/app/error-boundary/error';

import { QueryClientProvider } from './query-client-provider';
import { ReduxProvider } from './redux-provider';
import { ThemeProvider } from './theme-provider';
import { TokenExpirationProvider } from './token-expiration-provider';
import { Props } from './types';
import { VerificationCodeProvider } from './verification-code-provider';

export const WithContextProviders = ({ children }: Props): ReactElement => (
  <ThemeProvider>
    <ErrorBoundary>
      <ReduxProvider>
        <QueryClientProvider>
          <TokenExpirationProvider>
            <VerificationCodeProvider>{children}</VerificationCodeProvider>
          </TokenExpirationProvider>
        </QueryClientProvider>
      </ReduxProvider>
    </ErrorBoundary>
  </ThemeProvider>
);
