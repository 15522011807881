import { OrderDetails } from '~/api/requests';

import { FileErrors } from '../new-recharge.hook';
import { NewRechargeSteps } from '../types';
import { useRechargeValidation } from './validation.hook';
import { RechargeValidationLayout } from './validation.layout';

interface IRechargeValidation {
  file: File | null;
  onChangeNewRechargeStep: (step: NewRechargeSteps) => void;
  onChangeFileErrors: (errors: FileErrors) => void;
  onChangeFileData: (data: OrderDetails) => void;
}

export const RechargeValidation = ({
  file,
  onChangeNewRechargeStep,
  onChangeFileErrors,
  onChangeFileData,
}: IRechargeValidation) => {
  const props = useRechargeValidation({
    onChangeNewRechargeStep,
    onChangeFileErrors,
    onChangeFileData,
    file,
  });

  return (
    <RechargeValidationLayout file={file} onChangeNewRechargeStep={onChangeNewRechargeStep} {...props} />
  );
};
