// generated with @7nohe/openapi-react-query-codegen@undefined 

import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { ApplicationService, AuthService, BatchProposalService, CardsService, CommonService, DefaultService, EmployeeService, EmployerService, LoadOrderService, SecurityService, UserService } from "../requests/services.gen";
import { AddressDto, AssociateUserCompaniesDto, CardBatchSortEnum, ChangePasswordDto, CheckValidationRequestDto, CreateBatchProposalRequestDto, CreateCardBatchBodyDto, CreateEmployerDto, CreateOrderBodyDto, CreateUserRequestDto, DisassociateUserCompanyDto, EmployeeOnboardingPayload, ExistsDto, LoginUserDto, OrderEnum, OrderStatusEnum, ResendEmailRequestDto, SortLegalPersonEnum, SplitModeEnum, SwithRoleDto, UpdateEmployeeDto, UpdateUserRequestDto, ValidationDto } from "../requests/types.gen";
import * as Common from "./common";
/**
* List all users
* @param data The data for the request.
* @param data.page
* @param data.perPage
* @returns UserListResponseDto List all users successfully
* @throws ApiError
*/
export const useUserServiceUserControllerGetAllUsers = <TData = Common.UserServiceUserControllerGetAllUsersDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ page, perPage }: {
  page?: number;
  perPage?: number;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUserServiceUserControllerGetAllUsersKeyFn({ page, perPage }, queryKey), queryFn: () => UserService.userControllerGetAllUsers({ page, perPage }) as TData, ...options });
/**
* Fetches logged in user data
* @returns UserMeResponseDto Get user profile
* @throws ApiError
*/
export const useUserServiceUserControllerGetUser = <TData = Common.UserServiceUserControllerGetUserDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUserServiceUserControllerGetUserKeyFn(queryKey), queryFn: () => UserService.userControllerGetUser() as TData, ...options });
/**
* List all companies user
* @returns UserCompanyResponseDto Get user profile
* @throws ApiError
*/
export const useUserServiceUserControllerGetLoggedUserCompanies = <TData = Common.UserServiceUserControllerGetLoggedUserCompaniesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUserServiceUserControllerGetLoggedUserCompaniesKeyFn(queryKey), queryFn: () => UserService.userControllerGetLoggedUserCompanies() as TData, ...options });
/**
* List all companies specific user
* @param data The data for the request.
* @param data.userId
* @returns UserCompanyResponseDto Get user profile
* @throws ApiError
*/
export const useUserServiceUserControllerGetUserCompanies = <TData = Common.UserServiceUserControllerGetUserCompaniesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ userId }: {
  userId: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUserServiceUserControllerGetUserCompaniesKeyFn({ userId }, queryKey), queryFn: () => UserService.userControllerGetUserCompanies({ userId }) as TData, ...options });
/**
* Download proposal batch example
* @returns StreamableFile Download proposal batch example successfully
* @throws ApiError
*/
export const useBatchProposalServiceBatchProposalControllerDownloadExample = <TData = Common.BatchProposalServiceBatchProposalControllerDownloadExampleDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseBatchProposalServiceBatchProposalControllerDownloadExampleKeyFn(queryKey), queryFn: () => BatchProposalService.batchProposalControllerDownloadExample() as TData, ...options });
/**
* Search proposal batches at DockOne
* @param data The data for the request.
* @param data.page
* @param data.perPage
* @param data.createdAt
* @param data.proposalBatchId
* @param data.statusId
* @returns GetProposalBatchesResponseDto Search proposal batches successfully
* @throws ApiError
*/
export const useBatchProposalServiceBatchProposalControllerGetProposalBatches = <TData = Common.BatchProposalServiceBatchProposalControllerGetProposalBatchesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ createdAt, page, perPage, proposalBatchId, statusId }: {
  createdAt?: string;
  page?: number;
  perPage?: number;
  proposalBatchId?: string;
  statusId?: number;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseBatchProposalServiceBatchProposalControllerGetProposalBatchesKeyFn({ createdAt, page, perPage, proposalBatchId, statusId }, queryKey), queryFn: () => BatchProposalService.batchProposalControllerGetProposalBatches({ createdAt, page, perPage, proposalBatchId, statusId }) as TData, ...options });
/**
* Search proposals of a specific batch at DockOne
* @param data The data for the request.
* @param data.id
* @param data.page
* @param data.perPage
* @param data.document
* @param data.proposalId
* @param data.proposalOnboardingId
* @param data.statusId
* @returns GetSpecificBatchResponseDto Search proposals successfully
* @throws ApiError
*/
export const useBatchProposalServiceBatchProposalControllerGetSpecificBatch = <TData = Common.BatchProposalServiceBatchProposalControllerGetSpecificBatchDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ document, id, page, perPage, proposalId, proposalOnboardingId, statusId }: {
  document?: string;
  id: string;
  page?: number;
  perPage?: number;
  proposalId?: string;
  proposalOnboardingId?: string;
  statusId?: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseBatchProposalServiceBatchProposalControllerGetSpecificBatchKeyFn({ document, id, page, perPage, proposalId, proposalOnboardingId, statusId }, queryKey), queryFn: () => BatchProposalService.batchProposalControllerGetSpecificBatch({ document, id, page, perPage, proposalId, proposalOnboardingId, statusId }) as TData, ...options });
/**
* Search proposal Batch status at DockOne
* @param data The data for the request.
* @param data.id
* @returns GetSpecificBatchStatusResponseDto Search proposal status successfully
* @throws ApiError
*/
export const useBatchProposalServiceBatchProposalControllerGetSpecificBatchStatus = <TData = Common.BatchProposalServiceBatchProposalControllerGetSpecificBatchStatusDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseBatchProposalServiceBatchProposalControllerGetSpecificBatchStatusKeyFn({ id }, queryKey), queryFn: () => BatchProposalService.batchProposalControllerGetSpecificBatchStatus({ id }) as TData, ...options });
/**
* Search proposal Batch File Errors at DockOne
* @param data The data for the request.
* @param data.id
* @returns GetSpecificBatchDetailsResponseDto Search proposal batch file errors successfully
* @throws ApiError
*/
export const useBatchProposalServiceBatchProposalControllerFileErrors = <TData = Common.BatchProposalServiceBatchProposalControllerFileErrorsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseBatchProposalServiceBatchProposalControllerFileErrorsKeyFn({ id }, queryKey), queryFn: () => BatchProposalService.batchProposalControllerFileErrors({ id }) as TData, ...options });
/**
* Search proposal Batch Details preview
* @param data The data for the request.
* @param data.id
* @returns GetSpecificBatchDetailsResponseDto Search proposal batch details successfully
* @throws ApiError
*/
export const useBatchProposalServiceBatchProposalControllerGetSpecificBatchDetails = <TData = Common.BatchProposalServiceBatchProposalControllerGetSpecificBatchDetailsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseBatchProposalServiceBatchProposalControllerGetSpecificBatchDetailsKeyFn({ id }, queryKey), queryFn: () => BatchProposalService.batchProposalControllerGetSpecificBatchDetails({ id }) as TData, ...options });
/**
* Reprocess proposal Batch
* @param data The data for the request.
* @param data.id
* @param data.onlyErrors
* @param data.onlyDeclined
* @returns StreamableFile Reprocess proposal batch successfully and return a CSV file
* @throws ApiError
*/
export const useBatchProposalServiceBatchProposalControllerReprocess = <TData = Common.BatchProposalServiceBatchProposalControllerReprocessDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id, onlyDeclined, onlyErrors }: {
  id: string;
  onlyDeclined?: boolean;
  onlyErrors?: boolean;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseBatchProposalServiceBatchProposalControllerReprocessKeyFn({ id, onlyDeclined, onlyErrors }, queryKey), queryFn: () => BatchProposalService.batchProposalControllerReprocess({ id, onlyDeclined, onlyErrors }) as TData, ...options });
/**
* @param data The data for the request.
* @param data.path
* @param data.method
* @returns Request
* @throws ApiError
*/
export const useSecurityServiceSecurityControllerGetRequestList = <TData = Common.SecurityServiceSecurityControllerGetRequestListDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ method, path }: {
  method?: string;
  path?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseSecurityServiceSecurityControllerGetRequestListKeyFn({ method, path }, queryKey), queryFn: () => SecurityService.securityControllerGetRequestList({ method, path }) as TData, ...options });
/**
* List Employees at DockOne
* @param data The data for the request.
* @param data.page
* @param data.perPage
* @param data.document
* @param data.name
* @param data.email
* @param data.order
* @param data.sort
* @param data.status
* @returns ListEmployeesResponseDto List Employees successfully
* @throws ApiError
*/
export const useEmployeeServiceEmployeeControllerListEmployees = <TData = Common.EmployeeServiceEmployeeControllerListEmployeesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ document, email, name, order, page, perPage, sort, status }: {
  document?: string;
  email?: string;
  name?: string;
  order?: OrderEnum;
  page?: number;
  perPage?: number;
  sort?: string;
  status?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseEmployeeServiceEmployeeControllerListEmployeesKeyFn({ document, email, name, order, page, perPage, sort, status }, queryKey), queryFn: () => EmployeeService.employeeControllerListEmployees({ document, email, name, order, page, perPage, sort, status }) as TData, ...options });
/**
* Get Employees sheet via email
* @returns GetEmployeesSheetViaEmailResponseDto Employee sheet is being processed to be sent to email
* @throws ApiError
*/
export const useEmployeeServiceEmployeeControllerGetEmployeesSheetViaEmail = <TData = Common.EmployeeServiceEmployeeControllerGetEmployeesSheetViaEmailDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseEmployeeServiceEmployeeControllerGetEmployeesSheetViaEmailKeyFn(queryKey), queryFn: () => EmployeeService.employeeControllerGetEmployeesSheetViaEmail() as TData, ...options });
/**
* Get Employee by id
* @param data The data for the request.
* @param data.id
* @returns EmployeeDto Get Employee successfully
* @throws ApiError
*/
export const useEmployeeServiceEmployeeControllerGetEmployee = <TData = Common.EmployeeServiceEmployeeControllerGetEmployeeDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseEmployeeServiceEmployeeControllerGetEmployeeKeyFn({ id }, queryKey), queryFn: () => EmployeeService.employeeControllerGetEmployee({ id }) as TData, ...options });
/**
* @param data The data for the request.
* @param data.key
* @returns unknown
* @throws ApiError
*/
export const useDefaultServiceFileControllerGetSignedUrl = <TData = Common.DefaultServiceFileControllerGetSignedUrlDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ key }: {
  key: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseDefaultServiceFileControllerGetSignedUrlKeyFn({ key }, queryKey), queryFn: () => DefaultService.fileControllerGetSignedUrl({ key }) as TData, ...options });
/**
* @param data The data for the request.
* @param data.key
* @returns unknown
* @throws ApiError
*/
export const useDefaultServiceFileControllerDownloadFile = <TData = Common.DefaultServiceFileControllerDownloadFileDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ key }: {
  key: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseDefaultServiceFileControllerDownloadFileKeyFn({ key }, queryKey), queryFn: () => DefaultService.fileControllerDownloadFile({ key }) as TData, ...options });
/**
* List orders
* @param data The data for the request.
* @param data.page
* @param data.perPage
* @param data.createdAt
* @param data.loadOrderId
* @param data.status
* @returns ListOrdersResponseDto List load order successfully
* @throws ApiError
*/
export const useLoadOrderServiceOrderControllerListOrders = <TData = Common.LoadOrderServiceOrderControllerListOrdersDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ createdAt, loadOrderId, page, perPage, status }: {
  createdAt?: string;
  loadOrderId?: string;
  page?: number;
  perPage?: number;
  status?: OrderStatusEnum;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseLoadOrderServiceOrderControllerListOrdersKeyFn({ createdAt, loadOrderId, page, perPage, status }, queryKey), queryFn: () => LoadOrderService.orderControllerListOrders({ createdAt, loadOrderId, page, perPage, status }) as TData, ...options });
/**
* Detailed order
* @param data The data for the request.
* @param data.id
* @returns OrderDetails Detailed order successfully
* @throws ApiError
*/
export const useLoadOrderServiceOrderControllerOrderDetails = <TData = Common.LoadOrderServiceOrderControllerOrderDetailsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseLoadOrderServiceOrderControllerOrderDetailsKeyFn({ id }, queryKey), queryFn: () => LoadOrderService.orderControllerOrderDetails({ id }) as TData, ...options });
/**
* Reprocess load order
* @param data The data for the request.
* @param data.id
* @returns StreamableFile Reprocess load order successfully and return a CSV file
* @throws ApiError
*/
export const useLoadOrderServiceOrderControllerReprocess = <TData = Common.LoadOrderServiceOrderControllerReprocessDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseLoadOrderServiceOrderControllerReprocessKeyFn({ id }, queryKey), queryFn: () => LoadOrderService.orderControllerReprocess({ id }) as TData, ...options });
/**
* Get order details via email
* @param data The data for the request.
* @param data.id
* @returns SendViaEmailResponseDto Order details are being processed to be sent by email
* @throws ApiError
*/
export const useLoadOrderServiceOrderControllerGetOrderDetailsViaEmail = <TData = Common.LoadOrderServiceOrderControllerGetOrderDetailsViaEmailDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseLoadOrderServiceOrderControllerGetOrderDetailsViaEmailKeyFn({ id }, queryKey), queryFn: () => LoadOrderService.orderControllerGetOrderDetailsViaEmail({ id }) as TData, ...options });
/**
* Get order statement PDF
* @param data The data for the request.
* @param data.id
* @returns StreamableFile Statement PDF successfully generated and returned
* @throws ApiError
*/
export const useLoadOrderServiceOrderControllerGetStatement = <TData = Common.LoadOrderServiceOrderControllerGetStatementDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseLoadOrderServiceOrderControllerGetStatementKeyFn({ id }, queryKey), queryFn: () => LoadOrderService.orderControllerGetStatement({ id }) as TData, ...options });
/**
* Send order statement PDF via email
* @param data The data for the request.
* @param data.id
* @returns SendViaEmailResponseDto Statement PDF is being processed to be sent to email
* @throws ApiError
*/
export const useLoadOrderServiceOrderControllerGetStatementPdfViaEmail = <TData = Common.LoadOrderServiceOrderControllerGetStatementPdfViaEmailDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseLoadOrderServiceOrderControllerGetStatementPdfViaEmailKeyFn({ id }, queryKey), queryFn: () => LoadOrderService.orderControllerGetStatementPdfViaEmail({ id }) as TData, ...options });
/**
* Get account receive payments
* @returns TenantPaymentAccountDetails Get account receive payments successfully
* @throws ApiError
*/
export const useEmployerServiceEmployerControllerGetAccountThatWillReceivePayments = <TData = Common.EmployerServiceEmployerControllerGetAccountThatWillReceivePaymentsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseEmployerServiceEmployerControllerGetAccountThatWillReceivePaymentsKeyFn(queryKey), queryFn: () => EmployerService.employerControllerGetAccountThatWillReceivePayments() as TData, ...options });
/**
* List employer addresses
* @returns AddressDto List employer addresses successfully
* @throws ApiError
*/
export const useEmployerServiceEmployerControllerGetEmployerAddresses = <TData = Common.EmployerServiceEmployerControllerGetEmployerAddressesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseEmployerServiceEmployerControllerGetEmployerAddressesKeyFn(queryKey), queryFn: () => EmployerService.employerControllerGetEmployerAddresses() as TData, ...options });
/**
* Get employer basic info
* @returns GetEmployerBasicInfoResponseDto Get employer basic info successfully
* @throws ApiError
*/
export const useEmployerServiceEmployerControllerGetEmployerBasicInfo = <TData = Common.EmployerServiceEmployerControllerGetEmployerBasicInfoDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseEmployerServiceEmployerControllerGetEmployerBasicInfoKeyFn(queryKey), queryFn: () => EmployerService.employerControllerGetEmployerBasicInfo() as TData, ...options });
/**
* Get employer account balance
* @returns GetEmployerAccountBalanceResponseDto Get employer account balance successfully
* @throws ApiError
*/
export const useEmployerServiceEmployerControllerGetEmployerAccountBalance = <TData = Common.EmployerServiceEmployerControllerGetEmployerAccountBalanceDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseEmployerServiceEmployerControllerGetEmployerAccountBalanceKeyFn(queryKey), queryFn: () => EmployerService.employerControllerGetEmployerAccountBalance() as TData, ...options });
/**
* List Employer at DockOne
* @param data The data for the request.
* @param data.page
* @param data.perPage
* @param data.document
* @param data.name
* @param data.order
* @param data.sort
* @param data.status
* @returns ListEmployersResponseDto List Employer successfully
* @throws ApiError
*/
export const useEmployerServiceEmployerControllerListEmployees = <TData = Common.EmployerServiceEmployerControllerListEmployeesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ document, name, order, page, perPage, sort, status }: {
  document?: string;
  name?: string;
  order?: OrderEnum;
  page?: number;
  perPage?: number;
  sort?: SortLegalPersonEnum;
  status?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseEmployerServiceEmployerControllerListEmployeesKeyFn({ document, name, order, page, perPage, sort, status }, queryKey), queryFn: () => EmployerService.employerControllerListEmployees({ document, name, order, page, perPage, sort, status }) as TData, ...options });
/**
* Get Employer by id
* @param data The data for the request.
* @param data.id
* @returns EmployerDto Get Employer successfully
* @throws ApiError
*/
export const useEmployerServiceEmployerControllerGetEmployee = <TData = Common.EmployerServiceEmployerControllerGetEmployeeDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseEmployerServiceEmployerControllerGetEmployeeKeyFn({ id }, queryKey), queryFn: () => EmployerService.employerControllerGetEmployee({ id }) as TData, ...options });
/**
* List card batches
* @param data The data for the request.
* @param data.page
* @param data.perPage
* @param data.status
* @param data.externalId External ID of the batch
* @param data.employerId Employer ID of the batch
* @param data.sort
* @param data.order
* @returns ListCardBatchesResponse List of card batches
* @throws ApiError
*/
export const useCardsServiceCardsControllerListBatches = <TData = Common.CardsServiceCardsControllerListBatchesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ employerId, externalId, order, page, perPage, sort, status }: {
  employerId?: string;
  externalId?: string;
  order?: OrderEnum;
  page?: number;
  perPage?: number;
  sort?: CardBatchSortEnum;
  status?: "processing" | "processed" | "pending" | "error" | "aborted" | "retry";
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseCardsServiceCardsControllerListBatchesKeyFn({ employerId, externalId, order, page, perPage, sort, status }, queryKey), queryFn: () => CardsService.cardsControllerListBatches({ employerId, externalId, order, page, perPage, sort, status }) as TData, ...options });
/**
* Get default expiration date for multiapp cards
* @returns GetCardExpirationResponseDto Default expiration date
* @throws ApiError
*/
export const useCardsServiceCardsControllerGetExpirationDate = <TData = Common.CardsServiceCardsControllerGetExpirationDateDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseCardsServiceCardsControllerGetExpirationDateKeyFn(queryKey), queryFn: () => CardsService.cardsControllerGetExpirationDate() as TData, ...options });
/**
* @param data The data for the request.
* @param data.externalId External ID of the batch card
* @returns GetBatchCardStatusResponseDto Default expiration date
* @throws ApiError
*/
export const useCardsServiceCardsControllerGetBatchCardStatus = <TData = Common.CardsServiceCardsControllerGetBatchCardStatusDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ externalId }: {
  externalId: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseCardsServiceCardsControllerGetBatchCardStatusKeyFn({ externalId }, queryKey), queryFn: () => CardsService.cardsControllerGetBatchCardStatus({ externalId }) as TData, ...options });
/**
* Get address by zipcode
* @param data The data for the request.
* @param data.zipCode
* @returns ZipCodeDto Get address by zipcode successfully
* @throws ApiError
*/
export const useCommonServiceCommonControllerGetAddressByZipCode = <TData = Common.CommonServiceCommonControllerGetAddressByZipCodeDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ zipCode }: {
  zipCode: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseCommonServiceCommonControllerGetAddressByZipCodeKeyFn({ zipCode }, queryKey), queryFn: () => CommonService.commonControllerGetAddressByZipCode({ zipCode }) as TData, ...options });
/**
* Get frontend and backend versions of the application
* @returns GetVersionsResponseDto Get frontend and backend versions of the application successfully
* @throws ApiError
*/
export const useApplicationServiceApplicationControllerGetVersions = <TData = Common.ApplicationServiceApplicationControllerGetVersionsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseApplicationServiceApplicationControllerGetVersionsKeyFn(queryKey), queryFn: () => ApplicationService.applicationControllerGetVersions() as TData, ...options });
/**
* Create a new user
* @param data The data for the request.
* @param data.requestBody
* @returns User User created successfully
* @throws ApiError
*/
export const useUserServiceUserControllerCreate = <TData = Common.UserServiceUserControllerCreateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: CreateUserRequestDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: CreateUserRequestDto;
}, TContext>({ mutationFn: ({ requestBody }) => UserService.userControllerCreate({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Resend the email
* @param data The data for the request.
* @param data.requestBody
* @returns unknown Resend email
* @throws ApiError
*/
export const useUserServiceUserControllerResendEmail = <TData = Common.UserServiceUserControllerResendEmailMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ResendEmailRequestDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ResendEmailRequestDto;
}, TContext>({ mutationFn: ({ requestBody }) => UserService.userControllerResendEmail({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Associate user with multiple companies
* @param data The data for the request.
* @param data.userId
* @param data.requestBody
* @returns unknown User associated with companies successfully
* @throws ApiError
*/
export const useUserServiceUserControllerAssociateUserWithCompanies = <TData = Common.UserServiceUserControllerAssociateUserWithCompaniesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: AssociateUserCompaniesDto;
  userId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: AssociateUserCompaniesDto;
  userId: string;
}, TContext>({ mutationFn: ({ requestBody, userId }) => UserService.userControllerAssociateUserWithCompanies({ requestBody, userId }) as unknown as Promise<TData>, ...options });
/**
* User login
* @param data The data for the request.
* @param data.requestBody
* @returns Session
* @throws ApiError
*/
export const useAuthServiceAuthControllerLoginByDocumentPassword = <TData = Common.AuthServiceAuthControllerLoginByDocumentPasswordMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: LoginUserDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: LoginUserDto;
}, TContext>({ mutationFn: ({ requestBody }) => AuthService.authControllerLoginByDocumentPassword({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Checks if the user exists
* @param data The data for the request.
* @param data.requestBody
* @returns ExistsResponseDto Exists user
* @throws ApiError
*/
export const useAuthServiceAuthControllerExists = <TData = Common.AuthServiceAuthControllerExistsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ExistsDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ExistsDto;
}, TContext>({ mutationFn: ({ requestBody }) => AuthService.authControllerExists({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Reset user password
* @param data The data for the request.
* @param data.requestBody
* @returns void no content
* @throws ApiError
*/
export const useAuthServiceAuthControllerChangePassword = <TData = Common.AuthServiceAuthControllerChangePasswordMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ChangePasswordDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ChangePasswordDto;
}, TContext>({ mutationFn: ({ requestBody }) => AuthService.authControllerChangePassword({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* User switch role
* @param data The data for the request.
* @param data.requestBody
* @returns Session
* @throws ApiError
*/
export const useAuthServiceAuthControllerLoginBySwithRole = <TData = Common.AuthServiceAuthControllerLoginBySwithRoleMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: SwithRoleDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: SwithRoleDto;
}, TContext>({ mutationFn: ({ requestBody }) => AuthService.authControllerLoginBySwithRole({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Create a batch proposal at DockOne
* @param data The data for the request.
* @param data.formData
* @returns CreateBatchProposalResponseDto Batch proposal created at DockOne successfully
* @throws ApiError
*/
export const useBatchProposalServiceBatchProposalControllerCreateBatchProposal = <TData = Common.BatchProposalServiceBatchProposalControllerCreateBatchProposalMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  formData: CreateBatchProposalRequestDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  formData: CreateBatchProposalRequestDto;
}, TContext>({ mutationFn: ({ formData }) => BatchProposalService.batchProposalControllerCreateBatchProposal({ formData }) as unknown as Promise<TData>, ...options });
/**
* Enqueue a batch proposal which has been processed at DockOne
* @param data The data for the request.
* @param data.requestBody
* @returns unknown Batch proposal enqueued successfully
* @throws ApiError
*/
export const useBatchProposalServiceBatchProposalControllerEnqueueBatchProposal = <TData = Common.BatchProposalServiceBatchProposalControllerEnqueueBatchProposalMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: EmployeeOnboardingPayload;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: EmployeeOnboardingPayload;
}, TContext>({ mutationFn: ({ requestBody }) => BatchProposalService.batchProposalControllerEnqueueBatchProposal({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.requestBody
* @returns CheckValidationResponseDto Security create validation
* @throws ApiError
*/
export const useSecurityServiceSecurityControllerCheck = <TData = Common.SecurityServiceSecurityControllerCheckMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: CheckValidationRequestDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: CheckValidationRequestDto;
}, TContext>({ mutationFn: ({ requestBody }) => SecurityService.securityControllerCheck({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* @returns unknown
* @throws ApiError
*/
export const useDefaultServiceFileControllerUploadFile = <TData = Common.DefaultServiceFileControllerUploadFileMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, void, TContext>, "mutationFn">) => useMutation<TData, TError, void, TContext>({ mutationFn: () => DefaultService.fileControllerUploadFile() as unknown as Promise<TData>, ...options });
/**
* Create a load order request at DockOne
* @param data The data for the request.
* @param data.formData
* @param data.splitMode
* @param data.splitScheduleDate
* @returns CreateOrderResponseDto Load order request created at DockOne successfully
* @throws ApiError
*/
export const useLoadOrderServiceOrderControllerCreateOrder = <TData = Common.LoadOrderServiceOrderControllerCreateOrderMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  formData: CreateOrderBodyDto;
  splitMode?: SplitModeEnum;
  splitScheduleDate?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  formData: CreateOrderBodyDto;
  splitMode?: SplitModeEnum;
  splitScheduleDate?: string;
}, TContext>({ mutationFn: ({ formData, splitMode, splitScheduleDate }) => LoadOrderService.orderControllerCreateOrder({ formData, splitMode, splitScheduleDate }) as unknown as Promise<TData>, ...options });
/**
* Cancel order
* @param data The data for the request.
* @param data.id
* @returns CancelOrderResponseDto Order successfully canceled
* @throws ApiError
*/
export const useLoadOrderServiceOrderControllerCancelOrder = <TData = Common.LoadOrderServiceOrderControllerCancelOrderMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
}, TContext>({ mutationFn: ({ id }) => LoadOrderService.orderControllerCancelOrder({ id }) as unknown as Promise<TData>, ...options });
/**
* Split balance to employees
* @param data The data for the request.
* @param data.id
* @returns SplitResponseDto Balance split successfully
* @throws ApiError
*/
export const useLoadOrderServiceOrderControllerSplit = <TData = Common.LoadOrderServiceOrderControllerSplitMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
}, TContext>({ mutationFn: ({ id }) => LoadOrderService.orderControllerSplit({ id }) as unknown as Promise<TData>, ...options });
/**
* Validate and load order and return its details
* @param data The data for the request.
* @param data.formData
* @returns OrderDetails Load order validated successfully
* @throws ApiError
*/
export const useLoadOrderServiceOrderControllerValidateOrder = <TData = Common.LoadOrderServiceOrderControllerValidateOrderMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  formData: CreateOrderBodyDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  formData: CreateOrderBodyDto;
}, TContext>({ mutationFn: ({ formData }) => LoadOrderService.orderControllerValidateOrder({ formData }) as unknown as Promise<TData>, ...options });
/**
* Create employer address
* @param data The data for the request.
* @param data.requestBody
* @returns unknown Create employer address successfully
* @throws ApiError
*/
export const useEmployerServiceEmployerControllerCreateEmployerAddress = <TData = Common.EmployerServiceEmployerControllerCreateEmployerAddressMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: AddressDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: AddressDto;
}, TContext>({ mutationFn: ({ requestBody }) => EmployerService.employerControllerCreateEmployerAddress({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Create a new Employee
* @param data The data for the request.
* @param data.requestBody
* @returns unknown Employee created successfully
* @throws ApiError
*/
export const useEmployerServiceEmployerControllerCreateEmployee = <TData = Common.EmployerServiceEmployerControllerCreateEmployeeMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: CreateEmployerDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: CreateEmployerDto;
}, TContext>({ mutationFn: ({ requestBody }) => EmployerService.employerControllerCreateEmployee({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Create card batch
* @param data The data for the request.
* @param data.requestBody
* @returns CreateCardBatchResponseDto Card batch created
* @throws ApiError
*/
export const useCardsServiceCardsControllerCreateBatch = <TData = Common.CardsServiceCardsControllerCreateBatchMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: CreateCardBatchBodyDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: CreateCardBatchBodyDto;
}, TContext>({ mutationFn: ({ requestBody }) => CardsService.cardsControllerCreateBatch({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Update a user
* @param data The data for the request.
* @param data.id
* @param data.requestBody
* @returns unknown User updated successfully
* @throws ApiError
*/
export const useUserServiceUserControllerUpdate = <TData = Common.UserServiceUserControllerUpdateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: UpdateUserRequestDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: UpdateUserRequestDto;
}, TContext>({ mutationFn: ({ id, requestBody }) => UserService.userControllerUpdate({ id, requestBody }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.id
* @param data.requestBody
* @returns Validation Security create validation
* @throws ApiError
*/
export const useSecurityServiceSecurityControllerValidate = <TData = Common.SecurityServiceSecurityControllerValidateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: ValidationDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: ValidationDto;
}, TContext>({ mutationFn: ({ id, requestBody }) => SecurityService.securityControllerValidate({ id, requestBody }) as unknown as Promise<TData>, ...options });
/**
* Edit employee
* @param data The data for the request.
* @param data.personId
* @param data.requestBody
* @returns void Edit employee successfully
* @throws ApiError
*/
export const useEmployeeServiceEmployeeControllerUpdateEmployee = <TData = Common.EmployeeServiceEmployeeControllerUpdateEmployeeMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  personId: string;
  requestBody: UpdateEmployeeDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  personId: string;
  requestBody: UpdateEmployeeDto;
}, TContext>({ mutationFn: ({ personId, requestBody }) => EmployeeService.employeeControllerUpdateEmployee({ personId, requestBody }) as unknown as Promise<TData>, ...options });
/**
* Update employer address
* @param data The data for the request.
* @param data.requestBody
* @returns void Update employer address successfully
* @throws ApiError
*/
export const useEmployerServiceEmployerControllerUpdateEmployerAddress = <TData = Common.EmployerServiceEmployerControllerUpdateEmployerAddressMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: AddressDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: AddressDto;
}, TContext>({ mutationFn: ({ requestBody }) => EmployerService.employerControllerUpdateEmployerAddress({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Delete a user
* @param data The data for the request.
* @param data.id
* @returns void User deleted successfully
* @throws ApiError
*/
export const useUserServiceUserControllerDelete = <TData = Common.UserServiceUserControllerDeleteMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
}, TContext>({ mutationFn: ({ id }) => UserService.userControllerDelete({ id }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.userId
* @param data.requestBody
* @returns unknown
* @throws ApiError
*/
export const useUserServiceUserControllerDisassociateUserFromCompanies = <TData = Common.UserServiceUserControllerDisassociateUserFromCompaniesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: DisassociateUserCompanyDto;
  userId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: DisassociateUserCompanyDto;
  userId: string;
}, TContext>({ mutationFn: ({ requestBody, userId }) => UserService.userControllerDisassociateUserFromCompanies({ requestBody, userId }) as unknown as Promise<TData>, ...options });
