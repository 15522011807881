import { IconographyNames } from '@vizir-banking/design-system';
import {
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  Icon as BaseIcon,
  Skeleton as BaseSkeleton,
  SkeletonVariants,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 24px auto 0 auto;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px 32px;
  border: 1px solid ${getColor('neutral', 'light')};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const Title = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_L,
})`
  color: ${getColor('neutral', 'darkest')};
`;

export const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  align-items: center;
`;

export const SecondaryButton = styled(BaseButton).attrs({
  buttonType: ButtonTypes.secondary,
  buttonSize: ButtonSizes.medium,
})`
  margin-top: 32px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
`;

export const Label = styled(Typography).attrs({
  variant: TypographyVariants.SUBTITLE,
})`
  color: ${getColor('neutral', 'darkest')};
  font-weight: 700;
`;

export const Icon = styled(BaseIcon).attrs(({ theme }) => ({
  iconName: IconographyNames.copy,
  color: theme.designSystem.palette.neutral.dark,
}))`
  width: 24px;
  height: 24px;
`;

export const Skeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.tag,
})`
  width: 150px;
`;

export const Value = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})`
  color: ${getColor('neutral', 'darker')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 32px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${getColor('success', 'lightest')};
`;

export const HeaderTitle = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XL,
})`
  color: ${getColor('neutral', 'darkest')};
`;

export const HeaderTitleBold = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XL,
})`
  display: inline;
  font-weight: 900;
  color: ${getColor('neutral', 'darkest')};
`;

export const HeaderSubtitle = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})`
  color: ${getColor('neutral', 'darkest')};
`;
