import { ErrorsEnum as ErrosEnumApi } from '~/api/constants';

export enum ErrorsEnumFront {
  INVALID_FILE = 'invalid_file',
  SPLIT_ORDER = 'split_order',
  CANCELED_REQUEST = 'canceled_request',
  UNPROCESSABLE_ENTITY = 'UNPROCESSABLE_ENTITY',
  INVALID_EMPLOYER_PHONE = 'invalid_employer_phone',
  BATCH_STATUS_ERROR = 'batch_status_error',
}

export const ErrorsEnum = {
  ...ErrosEnumApi,
  ...ErrorsEnumFront,
};
