import React from 'react';

import { useTranslation } from '~/translates/use-translate';

import { Button, Container, Description, Row, Support, Title, Wrapper } from './error.styles';

interface IErrorBoundary {
  children: React.ReactNode;
  hasError: boolean;
  onPress: () => Promise<void>;
}

export const ErrorBoundaryLayout = ({ children, hasError, onPress }: IErrorBoundary) => {
  const translate = useTranslation('errors.error_boundary');

  return hasError ? (
    <Container>
      <Wrapper>
        <Title>{translate('title')}</Title>
        <Support>{translate('support')}</Support>
        <Description>{translate('description')}</Description>
        <Row>
          <Button label={translate('button.understood')} onClick={onPress} />
        </Row>
      </Wrapper>
    </Container>
  ) : (
    <>{children}</>
  );
};
