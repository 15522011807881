import { OrderDetails } from '~/api/requests';
import { StepByStep, StepsType } from '~/components/step-by-step/step-by-step';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import { RechargeSteps } from '../types';
import { RechargeDistribution } from './distribution/distribution';
import { DistributionOptions } from './distribution/types';
import { FileErrors } from './new-recharge.hook';
import { PageHeader } from './new-recharge.styles';
import { RechargePayment } from './payment/payment';
import { RechargePaymentType } from './payment-type/payment-type';
import { PaymentOptions, PaymentSelectedOptions } from './payment-type/types';
import { RechargeReview } from './review/review';
import { RechargeSend } from './send/send';
import { NewRechargeSteps } from './types';
import { RechargeValidation } from './validation/validation';

interface INewRecharge {
  currentStep: NewRechargeSteps;
  onChangeStep: (step: NewRechargeSteps) => void;
  onChangeRechargeStep: (step: RechargeSteps) => void;
  file: File | null;
  onChangeFile: (file: File) => void;
  stepHasError: boolean;
  onSetStepHasError: (hasError: boolean) => void;
  fileErrors: FileErrors;
  onChangeFileErrors: (errors: FileErrors) => void;
  fileData: OrderDetails;
  onChangeFileData: (data: OrderDetails) => void;
  paymentTypes: PaymentSelectedOptions;
  onChangePaymentTypes: (value: PaymentOptions) => void;
  totalValue: number;
  creditBalance: number;
  isBalanceLoading: boolean;
  tedValue: number;
  onChangeTedValue: (value: number) => void;
  handleCreateOrder: () => Promise<void>;
  isOrderLoading: boolean;
  creationSuccess: boolean;
  distributionType: DistributionOptions | null;
  onChangeDistributionType: (type: DistributionOptions) => void;
  selectedDate: Date | null;
  onChangeSelectedDate: (date: Date | null) => void;
}

export const NewRechargeLayout = ({
  currentStep,
  onChangeStep,
  onChangeRechargeStep,
  file,
  onChangeFile,
  stepHasError,
  onSetStepHasError,
  fileErrors,
  onChangeFileErrors,
  fileData,
  onChangeFileData,
  paymentTypes,
  onChangePaymentTypes,
  totalValue,
  creditBalance,
  isBalanceLoading,
  tedValue,
  onChangeTedValue,
  handleCreateOrder,
  distributionType,
  onChangeDistributionType,
  selectedDate,
  onChangeSelectedDate,
  creationSuccess,
  isOrderLoading,
}: INewRecharge) => {
  const translate = useTranslation('pages.rh.recharge.newRecharge');

  const getPreviousStep = () => {
    const steps = {
      [NewRechargeSteps.SEND]: NewRechargeSteps.SEND,
      [NewRechargeSteps.VALIDATION]: NewRechargeSteps.SEND,
      [NewRechargeSteps.PAYMENT_METHOD]: NewRechargeSteps.SEND,
      [NewRechargeSteps.DISTRIBUTION_METHOD]: NewRechargeSteps.PAYMENT_METHOD,
      [NewRechargeSteps.PAYMENT]: NewRechargeSteps.SEND,
    };
    return steps[currentStep];
  };

  const renderHeader = () => {
    const goBack = () => {
      return currentStep !== NewRechargeSteps.SEND
        ? onChangeStep(getPreviousStep())
        : onChangeRechargeStep(RechargeSteps.BALANCE);
    };

    return <PageHeader leadingGoBack={goBack} title={translate('pageHeader.subtitle')} />;
  };

  const renderRechargeSend = () => {
    return (
      <RechargeSend
        file={file}
        onChangeFile={onChangeFile}
        onChangeRechargeStep={onChangeRechargeStep}
        onChangeNewRechargeStep={onChangeStep}
      />
    );
  };

  const renderRechargeReview = () => {
    return (
      <RechargeReview
        file={file}
        fileErrors={fileErrors}
        onChangeNewRechargeStep={onChangeStep}
        onChangeRechargeStep={onChangeRechargeStep}
        onSetStepHasError={onSetStepHasError}
      />
    );
  };

  const renderRechargeValidation = () => {
    return (
      <RechargeValidation
        file={file}
        onChangeFileData={onChangeFileData}
        onChangeNewRechargeStep={onChangeStep}
        onChangeFileErrors={onChangeFileErrors}
      />
    );
  };

  const renderPaymentMethod = () => {
    return (
      <RechargePaymentType
        paymentTypes={paymentTypes}
        onChangePaymentTypes={onChangePaymentTypes}
        onChangeNewRechargeStep={onChangeStep}
        fileData={fileData}
        totalValue={totalValue}
        creditBalance={creditBalance}
        isBalanceLoading={isBalanceLoading}
        onChangeTedValue={onChangeTedValue}
      />
    );
  };

  const renderDistributionMethod = () => {
    return (
      <RechargeDistribution
        fileData={fileData}
        paymentTypes={paymentTypes}
        distributionType={distributionType}
        selectedDate={selectedDate}
        onChangeDistributionType={onChangeDistributionType}
        onChangeSelectedDate={onChangeSelectedDate}
        totalValue={totalValue}
        creditBalance={creditBalance}
        onChangeNewRechargeStep={onChangeStep}
        handleCreateOrder={handleCreateOrder}
        isOrderLoading={isOrderLoading}
        creationSuccess={creationSuccess}
      />
    );
  };

  const renderPayment = () => {
    return <RechargePayment onChangeRechargeStep={onChangeRechargeStep} totalValue={tedValue} />;
  };

  const steps: StepsType[] = [
    {
      label: translate('stepByStep.step0'),
      renderContent: renderRechargeSend(),
    },
    {
      label: translate('stepByStep.step1'),
      renderContent: stepHasError ? renderRechargeReview() : renderRechargeValidation(),
    },
    {
      label: translate('stepByStep.step2'),
      renderContent: renderPaymentMethod(),
    },
    {
      label: translate('stepByStep.step3'),
      renderContent: stepHasError ? renderRechargeReview() : renderDistributionMethod(),
    },
    {
      label: translate('stepByStep.step4'),
      renderContent: renderPayment(),
    },
  ];

  const getStepNumber = (step: NewRechargeSteps) => {
    const steps = {
      [NewRechargeSteps.SEND]: 0,
      [NewRechargeSteps.VALIDATION]: 1,
      [NewRechargeSteps.PAYMENT_METHOD]: 2,
      [NewRechargeSteps.DISTRIBUTION_METHOD]: 3,
      [NewRechargeSteps.PAYMENT]: 4,
    };
    return steps[step];
  };

  return (
    <FragmentCol style={{ flex: 1 }}>
      {renderHeader()}
      <StepByStep steps={steps} currentStep={getStepNumber(currentStep)} hasError={stepHasError} />
      {/* {stepHasError ? renderRechargeReview() : renderRechargeSend()} */}
    </FragmentCol>
  );
};
