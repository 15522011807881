import { RechargeSteps } from '../../types';
import { CreateOrderErrorMessageEnum } from '../send/send.hook';
import { NewRechargeSteps } from '../types';
import { useRechargeReview } from './review.hook';
import { RechargeReviewLayout } from './review.layout';

interface IRechargeReview {
  file: File | null;
  onChangeNewRechargeStep: (tab: NewRechargeSteps) => void;
  onSetStepHasError: (hasError: boolean) => void;
  onChangeRechargeStep: (step: RechargeSteps) => void;
  fileErrors: { line: number; column: string; message: CreateOrderErrorMessageEnum }[];
}

export const RechargeReview = ({
  file,
  fileErrors,
  onChangeNewRechargeStep,
  onSetStepHasError,
  onChangeRechargeStep,
}: IRechargeReview) => {
  const props = useRechargeReview({ file, fileErrors });

  return (
    <RechargeReviewLayout
      {...props}
      fileErrors={fileErrors}
      onChangeNewRechargeStep={onChangeNewRechargeStep}
      onSetStepHasError={onSetStepHasError}
      onChangeRechargeStep={onChangeRechargeStep}
    />
  );
};
