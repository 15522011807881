import 'react-datepicker/dist/react-datepicker.css';

import { Divider, RadioButton } from '@vizir-banking/design-system/web';
import { formatToBRL } from 'brazilian-values';
import React, { forwardRef, ReactElement } from 'react';

import { OrderDetails } from '~/api/requests';
import { OrderSummary } from '~/components/order-summary/order-summary';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import { PaymentSelectedOptions } from '../payment-type/types';
import { NewRechargeSteps } from '../types';
import {
  ButtonWrapper,
  ChangeMonthButton,
  Column,
  DatePicker,
  DatePickerCustomSelect,
  DatePickerHeaderWrapper,
  DatePickerInput,
  DatePickerMonth,
  DatePickerSelect,
  DatePickerSelectItem,
  DatePickerSelectItems,
  DatePickerSelectSelected,
  DatePickerWrapper,
  DatePickerYear,
  DateWarning,
  DayContent,
  DayContentWrapper,
  OptionContainer,
  OptionTitle,
  OptionTitleWrapper,
  OptionWrapper,
  PrimaryButton,
  SummaryContainer,
  SummaryTitle,
  TertiaryButton,
  Title,
  TotalValueWrapper,
  Value,
  ValueBold,
} from './distribution.styles';
import { DistributionOptions, months } from './types';

interface IRechargeDistributionLayout {
  fileData: OrderDetails;
  distributionType: DistributionOptions | null;
  onChangeDistributionType: (type: DistributionOptions) => void;
  totalValue: number;
  creditBalance: number;
  paymentTypes: PaymentSelectedOptions;
  onChangeNewRechargeStep: (step: NewRechargeSteps) => void;
  selectedDate: Date | null;
  onChangeSelectedDate: (date: Date | null) => void;
  monthPickerState: {
    pickMonthOpen: boolean;
    visibleMonth: string;
  };
  onChangeMonthPickerState: (state: { pickMonthOpen?: boolean; visibleMonth?: string }) => void;
  isValidTedDate: (date: Date) => boolean;
  handleCreateOrder: () => Promise<void>;
  isOrderLoading: boolean;
  creationSuccess: boolean;
}

export const RechargeDistributionLayout = ({
  fileData,
  distributionType,
  onChangeDistributionType,
  totalValue,
  creditBalance,
  paymentTypes,
  onChangeNewRechargeStep,
  selectedDate,
  onChangeSelectedDate,
  monthPickerState,
  onChangeMonthPickerState,
  isValidTedDate,
  handleCreateOrder,
  creationSuccess,
  isOrderLoading,
}: IRechargeDistributionLayout): ReactElement => {
  const translate = useTranslation('pages.rh.recharge.newRecharge.tabs.distributionType');
  const visibleMonth = monthPickerState.visibleMonth;
  const pickMonthOpen = monthPickerState.pickMonthOpen;

  const handleMonthClick = (month: string) => {
    onChangeMonthPickerState({ pickMonthOpen: !monthPickerState.pickMonthOpen, visibleMonth: month });
  };

  const renderOrderSummary = () => {
    return (
      <SummaryContainer>
        <Column>
          <SummaryTitle>{translate('summary.title')}</SummaryTitle>
          <OrderSummary isLoading={false} data={fileData as unknown as OrderDetails} />
        </Column>
      </SummaryContainer>
    );
  };

  const isOptionActive = (type: DistributionOptions) => {
    return distributionType === type;
  };

  const renderDistributionType = (type: DistributionOptions) => {
    return (
      <OptionWrapper isActive={isOptionActive(type)} onClick={() => onChangeDistributionType(type)}>
        <RadioButton isActive={isOptionActive(type)} onClick={() => onChangeDistributionType(type)} />
        <Column>
          <OptionTitleWrapper>
            <OptionTitle>{translate(`options.${type}`)}</OptionTitle>
          </OptionTitleWrapper>
        </Column>
      </OptionWrapper>
    );
  };

  const willRender = (type: DistributionOptions) => {
    if (paymentTypes.ted && !paymentTypes.credits) {
      return creditBalance === 0
        ? type !== DistributionOptions.INSTANT_WEEKEND
        : type === DistributionOptions.MANUAL;
    }
    if (paymentTypes.credits && !paymentTypes.ted) {
      return type === DistributionOptions.INSTANT_WEEKEND || type === DistributionOptions.SCHEDULE;
    }
    if (paymentTypes.credits && paymentTypes.ted) {
      return type === DistributionOptions.INSTANT_WEEKDAY || type === DistributionOptions.SCHEDULE;
    }
  };

  const renderOptions = () => {
    const options = [
      DistributionOptions.INSTANT_WEEKDAY,
      DistributionOptions.INSTANT_WEEKEND,
      DistributionOptions.MANUAL,
      DistributionOptions.SCHEDULE,
    ];

    return options.map((option) => {
      return willRender(option) ? renderDistributionType(option) : null;
    });
  };

  const renderTotalValueSummary = () => {
    const isTed = paymentTypes.ted;
    const isCredits = paymentTypes.credits;
    const isAny = isTed || isCredits;
    const isBoth = isTed && isCredits;
    const finalValue = isBoth ? totalValue - creditBalance : totalValue;

    const renderBothOptions = () => {
      return (
        <Value>
          {translate('totalValue.bothOptionsFinal', {
            value: formatToBRL(totalValue),
            discount: formatToBRL(creditBalance),
          })}
        </Value>
      );
    };
    const renderCreditsOnly = () => {
      return (
        <Value>
          {translate('totalValue.creditsOnlyFinal', {
            value: formatToBRL(totalValue),
          })}
        </Value>
      );
    };
    const getTranslation = () => {
      if (isTed && isCredits) return 'both';
      if (isTed && !isCredits) return 'ted';
      if (!isTed && isCredits) return 'credits';
    };

    return (
      <TotalValueWrapper>
        <Value>
          {translate('totalValue.base')} {isAny && translate(`totalValue.${getTranslation()}`)}
        </Value>
        {isBoth && renderBothOptions()}
        {!isTed && isCredits && renderCreditsOnly()}
        {isTed && <ValueBold>{formatToBRL(finalValue)}</ValueBold>}
      </TotalValueWrapper>
    );
  };

  const renderSelectDate = () => {
    const CustomInput = forwardRef<HTMLButtonElement, { value: string; onClick: () => void }>(
      ({ value, onClick }, ref) => (
        <DatePickerInput onClick={onClick} ref={ref}>
          {value}
        </DatePickerInput>
      )
    );

    const renderDayContents = (day: number, date: Date) => {
      const isActive =
        date.getDate() == selectedDate?.getDate() &&
        date.getMonth() == selectedDate?.getMonth() &&
        date.getFullYear() == selectedDate?.getFullYear();
      const isDisabled = !isValidTedDate(date);

      return (
        <DayContentWrapper isDisabled={isDisabled}>
          <DayContent isActive={isActive} isDisabled={isDisabled}>
            {new Date(date).getDate()}
          </DayContent>
        </DayContentWrapper>
      );
    };
    const renderDatePicker = () => {
      const getYear = (date: Date) => date.getFullYear();
      const getMonth = (date: Date) => date.getMonth();

      return (
        <DatePicker
          renderCustomHeader={({
            date,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <DatePickerHeaderWrapper>
              <DatePickerCustomSelect>
                <DatePickerSelect value={translate(`datepicker.months.${visibleMonth}`)}>
                  {months.map((month) => (
                    <option key={month} value={month}>
                      <DatePickerMonth>{translate(`datepicker.months.${month}`)}</DatePickerMonth>
                    </option>
                  ))}
                </DatePickerSelect>

                <DatePickerSelectSelected
                  onClick={() => onChangeMonthPickerState({ pickMonthOpen: !pickMonthOpen })}
                >
                  <DatePickerMonth>
                    {translate(`datepicker.months.${visibleMonth.toLocaleLowerCase()}`) ||
                      translate(`datepicker.months.${months[getMonth(date)]}`)}
                  </DatePickerMonth>
                </DatePickerSelectSelected>

                <DatePickerSelectItems show={pickMonthOpen}>
                  {months.map((month) => (
                    <DatePickerSelectItem
                      key={month}
                      onClick={() => {
                        handleMonthClick(month);
                        changeMonth(months.indexOf(month));
                      }}
                    >
                      {translate(`datepicker.months.${month}`)}
                    </DatePickerSelectItem>
                  ))}
                </DatePickerSelectItems>
              </DatePickerCustomSelect>

              <DatePickerYear>{getYear(date)}</DatePickerYear>

              <ChangeMonthButton
                onClick={() => {
                  decreaseMonth();
                  onChangeMonthPickerState({ visibleMonth: months[getMonth(date) - 1] });
                }}
                disabled={prevMonthButtonDisabled}
                isLeft
              />
              <ChangeMonthButton
                onClick={() => {
                  increaseMonth();
                  onChangeMonthPickerState({ visibleMonth: months[getMonth(date) + 1] });
                }}
                disabled={nextMonthButtonDisabled}
              />
            </DatePickerHeaderWrapper>
          )}
          formatWeekDay={(nameOfDay) =>
            translate(`datepicker.weekdays.${nameOfDay.toLowerCase()}`).slice(0, 3)
          }
          selected={selectedDate}
          onChange={onChangeSelectedDate}
          showIcon
          dateFormat='dd/MM/yyyy'
          customInput={<CustomInput onClick={() => {}} value={'Select'} />}
          renderDayContents={renderDayContents}
          filterDate={isValidTedDate}
          placeholderText={translate('options.scheduleDate')}
          fixedHeight
          locale='pt-BR'
        />
      );
    };

    return (
      <>
        <DatePickerWrapper>
          <OptionTitle>{translate('options.scheduleDate')}</OptionTitle>
          {renderDatePicker()}
        </DatePickerWrapper>
        <DateWarning>{translate('options.scheduleDateWarning')}</DateWarning>
      </>
    );
  };

  const renderButtons = () => {
    return (
      <ButtonWrapper>
        {renderTotalValueSummary()}

        <TertiaryButton
          label={translate('buttons.goBack')}
          onClick={() => onChangeNewRechargeStep(NewRechargeSteps.PAYMENT_METHOD)}
        />
        <PrimaryButton
          label={translate('buttons.done')}
          isDisabled={!distributionType}
          isLoading={isOrderLoading}
          onClick={async () => {
            await handleCreateOrder();
            if (creationSuccess) onChangeNewRechargeStep(NewRechargeSteps.PAYMENT);
          }}
        />
      </ButtonWrapper>
    );
  };

  return (
    <FragmentCol style={{ flex: 1 }}>
      {renderOrderSummary()}
      <OptionContainer>
        <Title>{translate('title')}</Title>
        {renderOptions()}
        {distributionType === DistributionOptions.SCHEDULE && renderSelectDate()}
      </OptionContainer>
      <Divider />
      {renderButtons()}
    </FragmentCol>
  );
};
