import { ReactElement } from 'react';

import { CheckIcon, Divider, Dot, DotLabel, FailIcon, Wrapper } from './step-by-step.styles';

interface Props {
  steps: StepsType[];
  currentStep: number;
  hasError?: boolean;
}

export interface StepsType {
  label?: string;
  renderContent: ReactElement;
}

export const StepByStep = ({ steps, currentStep, hasError = false }: Props): ReactElement => {
  const step = currentStep < steps.length ? currentStep : steps.length - 1;

  return (
    <>
      <Wrapper>
        {steps.map((item, index) => (
          <DotLabel isActive={currentStep > index} hasError={index === currentStep && hasError}>
            {item.label}
          </DotLabel>
        ))}
      </Wrapper>
      <Wrapper>
        {steps.map((item, index) => (
          <>
            <Dot isActive={currentStep > index} hasError={index === currentStep && hasError}>
              {index === currentStep && hasError ? <FailIcon /> : index <= currentStep && <CheckIcon />}
            </Dot>
            {index !== steps.length - 1 && <Divider isActive={currentStep > index} />}
          </>
        ))}
      </Wrapper>
      {steps[step].renderContent}
    </>
  );
};
