import { TagTypes } from '@vizir-banking/design-system/web';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BatchProposalStatusEnum } from '~/api/constants';
import {
  useBatchProposalServiceBatchProposalControllerGetSpecificBatchDetails,
  useBatchProposalServiceBatchProposalControllerGetSpecificBatchStatus,
  useBatchProposalServiceBatchProposalControllerReprocess,
} from '~/api/queries';
import {
  BatchProposalStatusEnum as BatchProposalStatusEnumType,
  GetSpecificBatchDetailsResponseDto,
} from '~/api/requests';

export type BatchDetailsProps = {
  batch?: GetSpecificBatchDetailsResponseDto;
  id?: string;
  status?: BatchProposalStatusEnumType;
  isBatchLoading: boolean;
  isBatchStatusLoading: boolean;
  csvWithDeclinedProposals: string;
  tagType: TagTypes;
  notFound: boolean;
  goBack: () => void;
};

export const useBatchDetails = (): BatchDetailsProps => {
  const location = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState<GetSpecificBatchDetailsResponseDto>();

  const batchId = location?.state?.batchId ?? '';

  const {
    data: batch,
    isPending: isBatchPending,
    isError: isBatchError,
  } = useBatchProposalServiceBatchProposalControllerGetSpecificBatchDetails({ id: batchId }, undefined, {
    enabled: !!batchId,
  });

  const { data: declined } = useBatchProposalServiceBatchProposalControllerReprocess({ id: batchId });

  const {
    data: detailed,
    isPending: isDetailedPending,
    isError: isDetailedError,
  } = useBatchProposalServiceBatchProposalControllerGetSpecificBatchStatus({ id: batchId });

  useEffect(() => {
    setData(batch);
  }, [batch]);

  const goBack = () => navigate(-1);
  const getTagType = (status: BatchProposalStatusEnumType | '') => {
    switch (status) {
      case BatchProposalStatusEnum.PROCESSED:
        return TagTypes.success;
      case BatchProposalStatusEnum.FAILED:
        return TagTypes.negative;
      case BatchProposalStatusEnum.PARTIAL_PROCESSED || BatchProposalStatusEnum.PROCESSING:
        return TagTypes.warning;
      default:
        return TagTypes.neutral;
    }
  };

  return {
    batch: data,
    id: detailed?.proposalBatchId ?? batch?.proposalBatchId,
    status: detailed?.status,
    isBatchLoading: isBatchPending,
    isBatchStatusLoading: isDetailedPending,
    csvWithDeclinedProposals: (declined as string) ?? '',
    tagType: getTagType(detailed?.status ?? ''),
    notFound: isBatchError && isDetailedError,
    goBack,
  };
};
