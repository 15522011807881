import { RechargeSteps } from '../../types';
import { useRechargePayment } from './payment.hook';
import { RechargePaymentLayout } from './payment.layout';

interface IRechargePayment {
  onChangeRechargeStep: (step: RechargeSteps) => void;
  totalValue: number;
}

export const RechargePayment = ({ onChangeRechargeStep, totalValue }: IRechargePayment) => {
  const props = useRechargePayment({ onChangeRechargeStep });
  return <RechargePaymentLayout totalValue={totalValue} {...props} />;
};
