import { useNavigate } from 'react-router-dom';

import { RH_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/rh-portal';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { SuccessDialog } from '~/components/dialogs/success-dialog/success-dialog';
import { LeadingContentType, PageHeader } from '~/components/page-header/page-header';
import { useTranslation } from '~/translates/use-translate';

import { BatchCollaboratorsProps, BatchCollaboratorsSteps } from './batch-collaborators.hook';
import { Screen } from './batch-collaborators.styles';
import { BatchProposalReview } from './review/review';
import { SelectFile } from './select-file/select-file';
import { Send } from './send/send';

export const BatchCollaboratorsLayout = ({
  step,
  onFileChange,
  onDrop,
  file,
  onDeleteFile,
  isLoading,
  onSubmitFile,
  onSuccess,
  fileErrors,
  onChangeBatchProposalStep,
}: BatchCollaboratorsProps) => {
  const translate = useTranslation('pages.rh.batchCollaborators');
  const navigate = useNavigate();

  const successButton = {
    label: translate('success'),
    onClick: onSuccess,
  };

  const steps = {
    [BatchCollaboratorsSteps.SELECT_FILE]: <SelectFile onDrop={onDrop} onFileChange={onFileChange} />,
    [BatchCollaboratorsSteps.SEND]: (
      <Send file={file} onDelete={onDeleteFile} isLoading={isLoading} onSubmit={onSubmitFile} />
    ),
    [BatchCollaboratorsSteps.REVIEW]: (
      <BatchProposalReview
        file={file}
        fileErrors={fileErrors}
        onChangeBatchProposalStep={onChangeBatchProposalStep}
      />
    ),
  };

  return (
    <Screen>
      <PageHeader
        leadingContentType={LeadingContentType.GO_BACK}
        leadingGoBack={() => {
          navigate(RH_SCREENS_DEFINITIONS.listBatches);
        }}
        title={translate('title')}
      />
      {steps[step]}

      <SuccessDialog closeOnClickOutside={false} secondaryButtonProps={successButton} />
      <ErrorDialog />
    </Screen>
  );
};
