import { Table } from '@vizir-banking/design-system/web';
import { ReactElement } from 'react';

import { GetSpecificBatchDetailsResponseDto } from '~/api/requests';

import { DropdownProvider } from '../dropdown/dropdown-context';
import { ActionsTable, TableTypes } from './types';
import { DynamicTableLayout } from './variants/dynamic-table.layout';
import { ProposalsTableLayout } from './variants/proposals-table.layout';

type ExtractColumns<T> = (keyof T)[];

interface IActions<T> {
  label: ActionsTable;
  hasLoading?: boolean;
  onCondition?: (row: T) => boolean;
  onClick: (row: T) => Promise<void>;
}

interface IDynamicTable<T> {
  data: T[] | GetSpecificBatchDetailsResponseDto;
  columns: ExtractColumns<T>;
  type?: TableTypes;
  actions?: IActions<T>[];
  isLoading?: boolean;
  isReachEndLoading?: boolean;
  onRowClick?: (row: T) => void;
  isOrderTable?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DynamicTable = <T extends Record<string, any>>({
  data,
  columns,
  actions,
  isLoading,
  isReachEndLoading,
  type = TableTypes.DYNAMIC,
  onRowClick,
  isOrderTable = false,
}: IDynamicTable<T>) => {
  const renderDynamicTableLayout = () => (
    <DynamicTableLayout<T>
      data={data as T[]}
      columns={columns}
      actions={actions}
      isLoading={isLoading}
      isReachEndLoading={isReachEndLoading}
      onRowClick={onRowClick}
      isOrderTable={isOrderTable}
    />
  );

  const renderProposalsTableLayout = () => (
    <ProposalsTableLayout data={data as GetSpecificBatchDetailsResponseDto} isLoading={isLoading} />
  );

  const renderTableType = () => {
    const types: { [key: string]: ReactElement } = {
      [TableTypes.DYNAMIC]: renderDynamicTableLayout(),
      [TableTypes.PROPOSALS]: renderProposalsTableLayout(),
    };

    return types[type];
  };

  return (
    <DropdownProvider>
      <Table>{renderTableType()}</Table>
    </DropdownProvider>
  );
};
