import { useTranslation } from '~/translates/use-translate';

import {
  Banner,
  Container,
  DeleteIcon,
  FileContainer,
  FileIcon,
  FileInfoWrapper,
  FileName,
  FileSize,
  FileWrapper,
  Link,
  PrimaryButton,
} from './send.styles';

type SendProps = {
  file: File | null;
  onDelete: () => void;
  isLoading: boolean;
  onSubmit: () => Promise<void>;
};

export enum CreateBatchProposalErrorMessageEnum {
  INVALID_VALUE = 'invalid_value',
  INVALID_CPF = 'invalid_cpf',
  INVALID_DATE = 'invalid_date',
}

export const Send = ({ file, onDelete, isLoading, onSubmit }: SendProps) => {
  const translate = useTranslation('pages.rh.batchCollaborators.send');

  const name = file?.name ?? '';
  const sizeInBytes = file?.size ?? 0;
  const size = sizeInBytes / (1000 * 1000);

  return (
    <Container>
      <FileContainer>
        <FileWrapper>
          <FileIcon />

          <FileInfoWrapper>
            <FileName>{name}</FileName>
            <FileSize>{size.toFixed(3)} MB</FileSize>
          </FileInfoWrapper>
        </FileWrapper>

        <Link onClick={onDelete}>
          <DeleteIcon />
        </Link>
      </FileContainer>

      <Banner title={translate('banner.title')} supportingText={translate('banner.description')} />

      <PrimaryButton label={translate('button')} onClick={onSubmit} isLoading={isLoading} />
    </Container>
  );
};
