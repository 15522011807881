import { useEffect } from 'react';

import { NewRechargeSteps } from '../types';
import { PaymentOptions, PaymentSelectedOptions } from './types';

interface IUseRechargePaymentType {
  paymentTypes: PaymentSelectedOptions;
  handlePaymentTypeChange: (value: PaymentOptions) => void;
  onContinue: () => void;
  totalValue: number;
  creditBalance: number;
  isCreditsDisabled: boolean;
}

interface IUseRechargePaymentTypeProps {
  onChangeNewRechargeStep: (step: NewRechargeSteps) => void;
  paymentTypes: PaymentSelectedOptions;
  onChangePaymentTypes: (value: PaymentOptions) => void;
  totalValue: number;
  creditBalance: number;
  onChangeTedValue: (value: number) => void;
}

export const useRechargePaymentType = ({
  onChangeNewRechargeStep,
  onChangePaymentTypes,
  paymentTypes,
  totalValue,
  creditBalance,
  onChangeTedValue,
}: IUseRechargePaymentTypeProps): IUseRechargePaymentType => {
  const isCreditsDisabled = !(creditBalance > 0);

  const onContinue = () => onChangeNewRechargeStep(NewRechargeSteps.DISTRIBUTION_METHOD);

  const handlePaymentTypeChange = (value: PaymentOptions) => {
    onChangePaymentTypes(value);
  };

  useEffect(() => {
    const isCreditsSelected = paymentTypes.credits;
    const isTedSelected = paymentTypes.ted;
    if (isCreditsSelected && !isTedSelected) {
      onChangeTedValue(0);
    }
    if (!isCreditsSelected && isTedSelected) {
      onChangeTedValue(totalValue);
    }
    if (!isCreditsSelected && !isTedSelected) {
      onChangeTedValue(0);
    }
    if (isCreditsSelected && isTedSelected) {
      onChangeTedValue(totalValue - creditBalance);
    }
  }, [paymentTypes, totalValue, creditBalance]);

  return {
    paymentTypes,
    handlePaymentTypeChange,
    onContinue,
    creditBalance,
    totalValue,
    isCreditsDisabled,
  };
};
