import { RechargeSteps } from '../types';
import { useNewRecharge } from './new-recharge.hook';
import { NewRechargeLayout } from './new-recharge.layout';

interface INewRecharge {
  onChangeStep: (step: RechargeSteps) => void;
}

export const NewRechargePage = ({ onChangeStep }: INewRecharge) => {
  const props = useNewRecharge({ onChangeRechargeStep: onChangeStep });

  return <NewRechargeLayout {...props} onChangeRechargeStep={onChangeStep} />;
};
