import { useEmployerServiceEmployerControllerGetAccountThatWillReceivePayments } from '~/api/queries';
import { EmployerControllerGetAccountThatWillReceivePaymentsResponse } from '~/api/requests';

import { RechargeSteps } from '../../types';

interface IUseRechargePayment {
  info?: EmployerControllerGetAccountThatWillReceivePaymentsResponse;
  isLoading: boolean;
  goBack: () => void;
}

interface IUseRechargePaymentProps {
  onChangeRechargeStep: (step: RechargeSteps) => void;
}

export const useRechargePayment = ({
  onChangeRechargeStep,
}: IUseRechargePaymentProps): IUseRechargePayment => {
  const useAccount = useEmployerServiceEmployerControllerGetAccountThatWillReceivePayments();

  const goBack = () => {
    onChangeRechargeStep(RechargeSteps.BALANCE);
  };

  return {
    info: useAccount?.data,
    isLoading: useAccount?.isLoading,
    goBack,
  };
};
