import { IconographyNames } from '@vizir-banking/design-system';
import { ButtonProps as BaseButtonProps } from '@vizir-banking/design-system/web';
import { noop } from 'lodash';
import { useSelector } from 'react-redux';

import { SimplifiedEmployees } from '~/api/requests';
import { TrailingContentType } from '~/components/page-header/page-header';
import { DynamicTable } from '~/components/tables/table.layout';
import { selectCurrentUser } from '~/redux/reducers/user';
import { useTranslation } from '~/translates/use-translate';

import { SendEmailHookProps } from './employee-list.hook';
import {
  Container,
  ContentArea,
  PageHeader,
  primarySuccessButtonStyleProps,
  Screen,
  Search,
  SendEmailDialog,
  sendEmailPrimaryButtonStyleProps,
  sendEmailTertiaryButtonStyleProps,
  SuccessDialog,
} from './employee-list.styles';

interface IEmployeeLayout {
  document: string;
  shouldViewEmployeeProfile: boolean;
  error: string;
  isLoading: boolean;
  onReachEndLoading: boolean;
  employees?: SimplifiedEmployees[];
  onSelectEmployee: (employee: SimplifiedEmployees) => void;
  onChangeDocument: (value: string) => void;
  onSendEmail: () => void;
  sendEmailHookState: SendEmailHookProps;
  onChangeSendEmailHookState: (state: SendEmailHookProps) => void;
}

export const EmployeeListLayout = ({
  document,
  shouldViewEmployeeProfile,
  error,
  isLoading,
  onReachEndLoading,
  employees,
  onSelectEmployee,
  onChangeDocument,
  onSendEmail,
  sendEmailHookState,
  onChangeSendEmailHookState,
}: IEmployeeLayout) => {
  const translate = useTranslation('pages.rh.employees.view');
  const { isSendEmailModalVisible, isSendEmailLoading, isSuccessModalVisible } = sendEmailHookState;
  const userEmail = useSelector(selectCurrentUser)?.email;
  type ButtonProps = Omit<BaseButtonProps, 'buttonSize' | 'buttonType'>;

  const sendEmailPrimaryButtonProps: ButtonProps = {
    label: translate('sendEmailDialog.confirm'),
    onClick: () => onSendEmail(),
    containerProps: sendEmailPrimaryButtonStyleProps,
    isLoading: isSendEmailLoading,
  };

  const sendEmailTertiaryButtonProps: ButtonProps = {
    label: translate('sendEmailDialog.cancel'),
    onClick: () => onChangeSendEmailHookState({ isSendEmailModalVisible: !isSendEmailModalVisible }),
    containerProps: sendEmailTertiaryButtonStyleProps,
  };

  const primarySuccessButtonProps: ButtonProps = {
    label: 'Ok',
    onClick: () => onChangeSendEmailHookState({ isSuccessModalVisible: !isSuccessModalVisible }),
    containerProps: primarySuccessButtonStyleProps,
  };
  return (
    <Screen>
      <Container>
        <ContentArea>
          <PageHeader
            leadingText={translate('pageHeader.currentStep')}
            title={translate('pageHeader.subtitle')}
            trailingContentType={TrailingContentType.BUTTON}
            trailingButton={{
              label: translate('pageHeader.button'),
              onClick: () =>
                onChangeSendEmailHookState({ isSendEmailModalVisible: !isSendEmailModalVisible }),
              withLeadingIcon: true,
              leadingIconName: IconographyNames.email,
              isLoading: isSendEmailLoading || isLoading,
              isDisabled: !employees?.length,
            }}
          />
          <Search
            onChange={onChangeDocument}
            onClear={() => onChangeDocument('')}
            value={document}
            placeholder='Buscar por CPF'
            onClickSearch={noop}
            error={error}
          />
        </ContentArea>
        <DynamicTable<SimplifiedEmployees>
          data={employees ?? []}
          columns={['status', 'name', 'document', 'email']}
          isLoading={isLoading}
          isReachEndLoading={onReachEndLoading}
          onRowClick={shouldViewEmployeeProfile ? onSelectEmployee : undefined}
        />
      </Container>

      <SendEmailDialog
        isVisible={isSendEmailModalVisible}
        onOutsideClick={() =>
          onChangeSendEmailHookState({ isSendEmailModalVisible: !isSendEmailModalVisible })
        }
        title={translate('sendEmailDialog.title', {
          email: userEmail ?? '',
        })}
        primaryButtonProps={sendEmailPrimaryButtonProps}
        tertiaryButtonProps={sendEmailTertiaryButtonProps}
      >
        {translate('sendEmailDialog.content')}
      </SendEmailDialog>

      <SuccessDialog
        isVisible={isSuccessModalVisible}
        onOutsideClick={() => onChangeSendEmailHookState({ isSuccessModalVisible: !isSuccessModalVisible })}
        title={translate('successDialog.title')}
        primaryButtonProps={primarySuccessButtonProps}
      />
    </Screen>
  );
};
