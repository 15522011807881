export enum PaymentOptions {
  TED = 'ted',
  PIX = 'pix',
  BILLET = 'billet',
  CREDITS = 'credits',
}

export interface PaymentSelectedOptions {
  ted: boolean;
  pix: boolean;
  billet: boolean;
  credits: boolean;
}
