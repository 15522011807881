import { IconographyNames } from '@vizir-banking/design-system';
import {
  Accordion as BaseAccordion,
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  Table as BaseTable,
  TableCell as BaseTableCell,
  TableHeadCell as BaseTableHeadCell,
  TableRow as BaseTableRow,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import { AccordionDetailsProps } from '@vizir-banking/design-system/web/components/accordion/types';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

export const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
`;

export const TableWrapper = styled.div`
  margin-top: 24px;
`;

export const TableContainer = styled(BaseTable)`
  width: 100%;
  overflow-x: scroll;
  border-collapse: collapse;
`;

export const TableHeadRow = styled(BaseTableRow)`
  background-color: ${getColor('neutral', 'lighter')};
`;

export const TableBodyRow = styled(BaseTableRow)``;

export const TableHeadCell = styled(BaseTableHeadCell)`
  border: 1px solid ${getColor('neutral', 'light')};
`;

export const TableBodyCell = styled(BaseTableCell)`
  border: 1px solid ${getColor('neutral', 'lighter')};
`;

export const TableBodyErrorCell = styled(BaseTableCell)`
  z-index: 1;
  border: 1.5px solid ${getColor('error', 'medium')};
  background-color: ${({ theme }) => {
    const color = theme.designSystem.palette.error.medium;
    return `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(
      color.slice(5, 7),
      16
    )}, 0.30)`;
  }};
`;

export const ButtonWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

export const SecondaryButton = styled(BaseButton).attrs({
  buttonType: ButtonTypes.tertiary,
  buttonSize: ButtonSizes.small,
})`
  color: ${getColor('error', 'medium')};
`;

export const PrimaryButton = styled(BaseButton).attrs(() => ({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
}))``;

export const ErrorsContainer = styled.div`
  border-top: 1px solid ${getColor('error', 'light')};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
  max-height: 200px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: start;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    border-radius: 50%;
    width: 12x;

    &-track {
      background: ${getColor('neutral', 'lighter')};
    }

    &-thumb {
      background: ${getColor('neutral', 'medium')};
      border-radius: 8px;
    }
  }
`;

export const ErrorWrapper = styled.p`
  display: inline;
  margin-top: 8px;
`;

export const ErrorLine = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_XS,
})`
  display: inline;
  font-weight: 700;
  color: ${({ theme }) => theme.designSystem.palette.neutral.darkest};
`;

export const ErrorDescription = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_XS,
})`
  display: inline;
  font-weight: 400;
`;

export const Accordion = styled(BaseAccordion).attrs(({ accordionContentProps, theme }) => ({
  containerProps: {
    style: {
      width: '100%',
      padding: '0',
      zIndex: 0,
      flexDirection: 'column',
    },
  },
  headerContainerProps: {
    style: {
      borderBottom: `1px solid ${theme.designSystem.palette.error.medium}`,
      width: '100%',
    },
  },
  accordionContentProps: {
    accordionHeaderProps: {
      ...accordionContentProps.accordionHeaderProps,
      iconColor: theme.designSystem.palette.neutral.darkest,
      summaryProps: {
        style: {
          fontSize: 14,
          fontWeight: 400,
          color: theme.designSystem.palette.neutral.darkest,
        },
      },
      hasLeadingIcon: true,
      leadingIconName: IconographyNames.errorCircle,
      summaryContainerProps: {
        style: {
          padding: '4px 0',
          justifyContent: 'flex-start',
        },
      },
      containerProps: {
        style: {
          width: '100%',
          padding: '0',
          borderTop: `1px solid ${theme.designSystem.palette.error.medium}`,
          borderLeft: `1px solid ${theme.designSystem.palette.error.medium}`,
          borderRight: `1px solid ${theme.designSystem.palette.error.medium}`,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
        },
      },
      customBackgroundColor: () => {
        const color = theme.designSystem.palette.error.medium;
        return `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(
          color.slice(5, 7),
          16
        )}, 0.30)`;
      },
      iconContainer: {
        style: {
          padding: '0 16px',
          color: theme.designSystem.palette.neutral.darkest,
        },
      },
    },
    accordionDetailsProps: [
      {
        ...(accordionContentProps?.accordionDetailsProps?.[0] ?? ({} as AccordionDetailsProps)),
        customBorderColor: theme.designSystem.palette.error.medium,
        customBackgroundColor: () => {
          const color = theme.designSystem.palette.error.medium;
          return `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(
            color.slice(5, 7),
            16
          )}, 0.30)`;
        },
        containerProps: {
          style: {
            width: '100%',
            padding: '0',
            flexDirection: 'column',
            borderLeft: `1px solid ${theme.designSystem.palette.error.medium}`,
            borderRight: `1px solid ${theme.designSystem.palette.error.medium}`,
            borderRadius: 4,
          },
        },
      },
    ],
  },
  detailsPositionalContainer: {
    style: {
      width: '100%',
      flexDirection: 'column',
      zIndex: 1,
    },
  },
}))``;
