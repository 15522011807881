import { IconographyNames } from '@vizir-banking/design-system';
import { ChangeEvent, DragEvent, MouseEvent, ReactElement, useRef } from 'react';

import { Recharge } from '~/assets/sheets-mapping';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';
import { autoSingleStyle } from '~/utils/auto-single-style';

import { RechargeSteps } from '../../types';
import { NewRechargeSteps } from '../types';
import {
  ButtonWrapper,
  Container,
  Dialog,
  DialogLink,
  Divider,
  DividerText,
  DividerWrapper,
  ErrorNotification,
  FileDragArea,
  Icon,
  Link,
  Paragraph,
  PrimaryButton,
  Row,
  Title,
  Top,
} from './send.styles';

interface IRechargeSend {
  file: File | null;
  onChangeNewRechargeStep: (step: NewRechargeSteps) => void;
  onChangeRechargeStep: (step: RechargeSteps) => void;
  error: string;
  onDrop: (event: DragEvent<HTMLFormElement>) => void;
  onFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onCloseError: () => void;
}
export const RechargeSendLayout = ({
  file,
  onChangeNewRechargeStep,
  onChangeRechargeStep,
  error,
  onDrop,
  onFileChange,
  onCloseError,
}: IRechargeSend): ReactElement => {
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const translate = useTranslation('pages.rh.recharge.newRecharge.tabs.send');

  const renderSheetModel = () => {
    const handleDownloadClick = () => {
      if (downloadLinkRef.current) {
        downloadLinkRef.current.click();
      }
    };

    return (
      <Top>
        <p>
          {autoSingleStyle(translate('sheetModel.title'), '*', Dialog, DialogLink, {
            onClick: handleDownloadClick,
          })}
        </p>

        <a
          href={Recharge}
          download='planilha_de_pedidos.xlsx'
          ref={downloadLinkRef}
          style={{ display: 'none' }}
        />
      </Top>
    );
  };

  const renderDivider = () => {
    return (
      <DividerWrapper>
        <Divider />
        <DividerText>{translate('divider')}</DividerText>
        <Divider />
      </DividerWrapper>
    );
  };

  const renderFileArea = () => {
    const onFileClick = (event: MouseEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();
      //@ts-expect-error - The input is hidden
      document.querySelector('.input-file')?.click();
    };

    const onDragOver = (event: DragEvent<HTMLFormElement>) => {
      event.preventDefault();
    };

    return (
      <FileDragArea action='' onClick={onFileClick} onDrop={onDrop} onDragOver={onDragOver}>
        <input
          type='file'
          className='input-file'
          hidden
          onClick={(event) => event.stopPropagation()}
          onChange={onFileChange}
        />
        <Row>
          <Icon iconName={IconographyNames.upload} />
          <Title>{translate('fileArea.title')}</Title>
        </Row>
        <Dialog>{translate('fileArea.formats')}</Dialog>

        <PrimaryButton label={translate('fileArea.button')} onClick={onFileClick} />
        {file && <Dialog>{file.name}</Dialog>}

        <Paragraph>{translate('fileArea.paragraph')}</Paragraph>

        {error && (
          <ErrorNotification
            leadingText={{
              label: error,
            }}
            trailingOnClose={onCloseError}
          />
        )}
      </FileDragArea>
    );
  };

  const renderButtons = () => {
    return (
      <ButtonWrapper>
        <Link onClick={() => onChangeRechargeStep(RechargeSteps.BALANCE)}>{translate('buttons.cancel')}</Link>
        <PrimaryButton
          label={translate('buttons.next')}
          onClick={() => onChangeNewRechargeStep(NewRechargeSteps.VALIDATION)}
          trailingIconName={IconographyNames.chevronRight}
          withTrailingIcon
          isDisabled={!file}
        />
      </ButtonWrapper>
    );
  };

  const renderContent = () => {
    return (
      <FragmentCol style={{ flex: 1 }}>
        {renderSheetModel()}
        {renderDivider()}
        {renderFileArea()}
        {renderButtons()}
      </FragmentCol>
    );
  };

  return (
    <Container>
      {renderContent()}

      <ErrorDialog />
    </Container>
  );
};
