import { ChangeEvent, DragEvent, useCallback, useState } from 'react';

interface IUseRechargeSend {
  error: string;
  onFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onDrop: (event: DragEvent<HTMLFormElement>) => void;
  onCloseError: () => void;
}

interface IRechargeSend {
  onChangeFile: (file: File) => void;
}

export enum CreateOrderErrorMessageEnum {
  DUPLICATED_CPF = 'duplicated_cpf',
  INVALID_VALUE = 'invalid_value',
  NOT_FOUND_CPF = 'not_found_cpf',
}

export const useRechargeSend = ({ onChangeFile }: IRechargeSend): IUseRechargeSend => {
  const [error, setError] = useState('');

  const onFileChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      validateFile(selectedFile);
    }
  }, []);

  const onDrop = useCallback((event: DragEvent<HTMLFormElement>) => {
    event.preventDefault();
    const selectedFile = event.dataTransfer.files?.[0];
    if (selectedFile) {
      validateFile(selectedFile);
    }
  }, []);

  const validateFile = (file: File) => {
    const validExtensions = ['xlsx', 'csv', 'ods', 'tsv'];
    const fileExtension = file.name.split('.').pop();
    if (fileExtension && validExtensions.includes(fileExtension)) {
      onChangeFile(file);
      setError('');
    } else {
      setError(`"${error}" não é um formato compatível`);
    }
  };

  const onCloseError = () => {
    setError('');
  };

  return {
    error,
    onDrop,
    onFileChange,
    onCloseError,
  };
};
