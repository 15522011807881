/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableBody, TableHead } from '@vizir-banking/design-system/web';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import { useOnReachEnd } from '~/hooks/use-reach-end';
import { useTranslation } from '~/translates/use-translate';
import { autoSingleStyle } from '~/utils/auto-single-style';

import { BatchCollaboratorsSteps } from '../batch-collaborators.hook';
import { CreateBatchProposalErrorMessageEnum } from '../send/send';
import {
  Accordion,
  ButtonWrapper,
  ErrorDescription,
  ErrorLine,
  ErrorsContainer,
  ErrorWrapper,
  PageWrapper,
  PrimaryButton,
  SecondaryButton,
  TableBodyCell,
  TableBodyErrorCell,
  TableBodyRow,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
  TableWrapper,
} from './review.styles';

interface IBatchProposalReview {
  validateData: (columnIndex: number, rowIndex: number) => boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[][];
  fileErrors: { line: number; column: string; message: CreateBatchProposalErrorMessageEnum }[];
  onChangeBatchProposalStep: (step: BatchCollaboratorsSteps) => void;
}

const PAGE_SIZE = 10;

export const BatchProposalReviewLayout = ({
  data,
  fileErrors,
  validateData,
  onChangeBatchProposalStep,
}: IBatchProposalReview) => {
  const translate = useTranslation('pages.rh.batchCollaborators.review');
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState<any[][]>(data.slice(0, PAGE_SIZE));

  useOnReachEnd(() => {
    if (currentPage * PAGE_SIZE > data.length) return;
    setCurrentPage((prev) => prev + 1);
  });

  useEffect(() => {
    if (data) {
      const newData = data.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);
      setCurrentData((prev) => (currentPage === 1 ? newData : [...prev, ...newData]));
    }
  }, [currentPage, data]);

  const renderTableHead = () => {
    if (data.length === 0) return null;
    return (
      <TableHeadRow>
        <TableHeadCell>{translate('errors.line')}</TableHeadCell>
        {data[0].map((header, index) => (
          <TableHeadCell key={index}>{header}</TableHeadCell>
        ))}
      </TableHeadRow>
    );
  };

  const renderTableBodyRows = () => {
    if (isEmpty(data)) return null;

    return currentData.slice(1).map(
      (row, index) =>
        row.length !== 1 && (
          <TableBodyRow key={index}>
            <TableHeadCell key={index}>{index + 1}</TableHeadCell>
            {row.map((cell, cellIndex) =>
              validateData(cellIndex, index) ? (
                <TableBodyCell key={cellIndex}>{cell}</TableBodyCell>
              ) : (
                <TableBodyErrorCell key={cellIndex}>{cell}</TableBodyErrorCell>
              )
            )}
          </TableBodyRow>
        )
    );
  };

  const renderTable = () => {
    return (
      <TableWrapper>
        <TableContainer>
          <TableHead>{renderTableHead()}</TableHead>
          <TableBody>{renderTableBodyRows()}</TableBody>
        </TableContainer>
      </TableWrapper>
    );
  };

  const renderButtons = () => {
    return (
      <ButtonWrapper>
        <SecondaryButton
          label={translate('buttons.cancel')}
          onClick={() => {
            onChangeBatchProposalStep(BatchCollaboratorsSteps.SELECT_FILE);
          }}
        />
        <PrimaryButton
          label={translate('buttons.resend')}
          onClick={() => {
            onChangeBatchProposalStep(BatchCollaboratorsSteps.SEND);
          }}
        />
      </ButtonWrapper>
    );
  };

  const renderErrors = () => {
    return (
      <ErrorsContainer>
        {fileErrors &&
          fileErrors.map((err, index) => {
            const line = 'errors.line';
            const column = `columns.${err.column}`;
            const message = `errors.${err.message}`;
            return (
              <ErrorWrapper key={index}>
                {autoSingleStyle(
                  `*${translate(line)} ${err.line}:* ${translate(column)} -> ` + translate(message),
                  '*',
                  ErrorDescription,
                  ErrorLine
                )}
              </ErrorWrapper>
            );
          })}
      </ErrorsContainer>
    );
  };

  const renderAccordionErrors = () => {
    if (fileErrors.length === 0) {
      return null;
    }

    return (
      <Accordion
        accordionContentProps={{
          accordionHeaderProps: { summaryLabel: `${fileErrors.length} ${translate('errors.errorsFound')}` },
          accordionDetailsProps: [{ renderContent: () => renderErrors() }],
        }}
      />
    );
  };

  return (
    <PageWrapper>
      {renderAccordionErrors()}
      {renderTable()}
      {renderButtons()}
    </PageWrapper>
  );
};
