import { BatchCollaboratorsSteps } from '../batch-collaborators.hook';
import { CreateBatchProposalErrorMessageEnum } from '../send/send';
import { useBatchProposalReview } from './review.hook';
import { BatchProposalReviewLayout } from './review.layout';

interface IBatchProposalReview {
  file: File | null;
  onChangeBatchProposalStep: (step: BatchCollaboratorsSteps) => void;
  fileErrors: { line: number; column: string; message: CreateBatchProposalErrorMessageEnum }[];
}

export const BatchProposalReview = ({
  file,
  fileErrors,
  onChangeBatchProposalStep,
}: IBatchProposalReview) => {
  const props = useBatchProposalReview({ file, fileErrors });

  return (
    <BatchProposalReviewLayout
      {...props}
      fileErrors={fileErrors}
      onChangeBatchProposalStep={onChangeBatchProposalStep}
    />
  );
};
