import { BannerTypes, TagSizes, TagTypes, TagVariants } from '@vizir-banking/design-system/web';
import { CSVLink } from 'react-csv';

import {
  BatchProposalStatusEnum as BatchProposalStatusEnumType,
  GetSpecificBatchDetailsResponseDto,
} from '~/api/requests';
import { FeedbackBanner } from '~/components/feedback-banner/feedback-banner';
import { LeadingContentType, PageHeader, TrailingContentType } from '~/components/page-header/page-header';
import { DynamicTable } from '~/components/tables/table.layout';
import { TableTypes } from '~/components/tables/types';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import { ButtonWrapper, Container, DownloadButton, Head, Paragraph, Screen } from './batch-details.styles';

interface IBatchDetailsLayout {
  batch?: GetSpecificBatchDetailsResponseDto;
  id?: string;
  status?: BatchProposalStatusEnumType;
  isBatchLoading: boolean;
  isBatchStatusLoading: boolean;
  csvWithDeclinedProposals: string;
  tagType: TagTypes;
  notFound: boolean;
  goBack: () => void;
}

export const BatchDetailsLayout = ({
  batch,
  id,
  status,
  isBatchLoading,
  isBatchStatusLoading,
  csvWithDeclinedProposals,
  tagType,
  notFound,
  goBack,
}: IBatchDetailsLayout) => {
  const translate = useTranslation('pages.rh.listBatches.batchDetails');
  const translateStatus = useTranslation('components.tables.status');

  const renderSkeletons = () => {
    return Array.from({ length: 3 }).map((_) => {
      return <FeedbackBanner isSkeleton />;
    });
  };

  return (
    <Screen>
      <Container>
        <Head>
          <PageHeader
            title={`${translate('batchNumber')}: ${isBatchStatusLoading ? '...' : id ?? 'N/A'}`}
            leadingContentType={LeadingContentType.GO_BACK}
            leadingGoBack={goBack}
            trailingContentType={TrailingContentType.TAG}
            trailingTag={{
              tagProps: {
                label: isBatchStatusLoading
                  ? '...'
                  : status
                  ? translateStatus(status)
                  : translate('batchNotFound'),
                size: TagSizes.large,
                type: status ? tagType : TagTypes.neutral,
                variant: TagVariants.primary,
              },
            }}
          />

          {isBatchLoading && renderSkeletons()}
          {notFound && <Paragraph>{translate('batchNotFound')}</Paragraph>}
          {!isBatchLoading && !notFound && (
            <FragmentCol style={{ gap: 16 }}>
              {(batch?.proposalsAccepted ?? 0) > 0 && (
                <FeedbackBanner
                  type={BannerTypes.success}
                  title={`${batch?.proposalsAccepted} ${translate('proposalsAccepted')}.`}
                />
              )}
              {(batch?.proposalsDeclined ?? 0) > 0 && (
                <FeedbackBanner
                  type={BannerTypes.error}
                  title={`${batch?.proposalsDeclined} ${translate('proposalsWithErrors')}.`}
                />
              )}
              {(batch?.proposalsWithErrors ?? 0) > 0 && (
                <FeedbackBanner
                  type={BannerTypes.warning}
                  title={`${batch?.proposalsWithErrors} ${translate('proposalsDeclined')}.`}
                />
              )}
            </FragmentCol>
          )}
        </Head>

        {!notFound && (
          <FragmentCol style={{ gap: 24 }}>
            <DynamicTable
              data={batch as GetSpecificBatchDetailsResponseDto}
              type={TableTypes.PROPOSALS}
              columns={[]}
              isLoading={isBatchLoading}
            />

            <ButtonWrapper>
              <CSVLink
                data={csvWithDeclinedProposals}
                filename='propostas_recusadas.csv'
                style={{ textDecoration: 'none' }}
              >
                <DownloadButton
                  onClick={() => {}}
                  label={translate('downloadRejectedSpreadsheet')}
                  isLoading={isBatchLoading}
                  isDisabled={isBatchLoading}
                />
              </CSVLink>
            </ButtonWrapper>
          </FragmentCol>
        )}
      </Container>
    </Screen>
  );
};
