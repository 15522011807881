import { ErrorsEnum } from './types';

export const ptBrErrorTranslations = {
  errors: {
    generic_error: {
      title: 'Ops, algo deu errado!',
      description: 'Tivemos um problema! Por favor, tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.AUTHENTICATION_FAILED]: {
      title: 'Ops, algo deu errado!',
      description: 'E-mail ou senha não correspondem.',
      button: 'Entendi',
    },
    [ErrorsEnum.VALIDATION_INVALID]: {
      title: 'Ops, algo deu errado!',
      description: 'Código de verificação incorreto.',
      button: 'Fechar',
    },
    [ErrorsEnum.EMAIL_NOT_SENT]: {
      title: 'Ops, algo deu errado!',
      description: 'Falha ao enviar o e-mail.',
      button: 'Fechar',
    },
    [ErrorsEnum.EXPIRED_ACCESS_CODE]: {
      title: 'Ops, algo deu errado!',
      description: 'Que pena, seu link expirou.',
      button: 'Fechar',
    },
    [ErrorsEnum.INVALID_PASSWORD_POLICY]: {
      title: 'Ops, algo deu errado!',
      description: 'A senha informada não cumpre os requisistos mínimos de segurança.',
      button: 'Fechar',
    },
    [ErrorsEnum.TOKEN_WITHOUT_SCOPES]: {
      title: 'Ops, algo deu errado!',
      description: 'Puxa, você não tem permissão para fazer isso.',
      button: 'Fechar',
    },
    [ErrorsEnum.INVALID_FILE]: {
      title: 'Ops, algo deu errado!',
      description: 'O arquivo selecionado não é um formato compatível.',
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_PROPOSAL_NO_FILE]: {
      title: 'Ops, algo deu errado!',
      description: 'Nenhum arquivo encontrado.',
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_PROPOSAL_EMPTY_FILE]: {
      title: 'Ops, algo deu errado!',
      description: 'O arquivo selecionado está vazio.',
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_PROPOSAL_EMPTY_COLUMNS]: {
      title: 'Ops, algo deu errado!',
      description: 'O arquivo selecionado não possui colunas.',
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_PROPOSAL_INVALID_COLUMNS]: {
      title: 'Colunas incompatíveis!',
      description:
        'O arquivo enviado não corresponde ao layout esperado para a proposta em lote. Por favor, verifique as colunas e tente novamente. Consulte o layout padrão.',
      button: 'Fechar',
    },
    [ErrorsEnum.LOAD_ORDER_INVALID_COLUMNS]: {
      title: 'Colunas incompatíveis!',
      description:
        'O arquivo enviado não corresponde ao layout esperado para recarga em lote. Por favor, verifique as colunas e tente novamente. Consulte o layout padrão.',
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_PROPOSAL_MAX_BATCH_SIZE]: {
      title: 'Ops, algo deu errado!',
      description: 'O arquivo selecionado é muito grande.',
      button: 'Entendi',
    },
    [ErrorsEnum.BATCH_PROPOSAL_MIN_BATCH_SIZE]: {
      title: 'Ops, algo deu errado!',
      description: 'O arquivo selecionado não possui o tamanho mínimo.',
      button: 'Entendi',
    },
    [ErrorsEnum.EXPIRED_TOKEN]: {
      title: 'Sessão Expirada',
      description: 'Sua sessão expirou. Clique no botão abaixo para fazer login novamente.',
      button: 'Entendi',
    },
    [ErrorsEnum.SPLIT_ORDER]: {
      title: 'Ops, algo deu errado!',
      description: 'Falha ao distribuir o pedido. Por favor, tente novamente.',
      button: 'Fechar',
    },
    [ErrorsEnum.ADDRESS_LIMIT_REACHED]: {
      title: 'Ops, algo deu errado!',
      description: 'Limite de endereços atingido.',
      button: 'Fechar',
    },
    [ErrorsEnum.LOAD_ORDER_MIN_ITEMS]: {
      title: 'Ops, algo deu errado!',
      description: 'O arquivo selecionado não possui o número mínimo de itens.',
      button: 'Fechar',
    },
    [ErrorsEnum.LOAD_ORDER_MAX_ITEMS]: {
      title: 'Ops, algo deu errado!',
      description: 'O arquivo selecionado excedeu o número máximo de itens (10000).',
      button: 'Fechar',
    },
    [ErrorsEnum.DOCK_ONE_BAD_REQUEST]: {
      title: 'Ops, algo deu errado!',
      description: 'Dados inválidos na planilha.',
      button: 'Fechar',
    },
    [ErrorsEnum.UNPROCESSABLE_ENTITY]: {
      title: 'Ops, algo deu errado!',
      description: 'Dados inválidos.',
      button: 'Fechar',
    },
    [ErrorsEnum.INVALID_EMPLOYER_PHONE]: {
      title: 'Ops, algo deu errado!',
      description: 'Telefone da empresa inválido.',
      button: 'Fechar',
    },
    [ErrorsEnum.EMPLOYER_ALREADY_EXISTS]: {
      title: 'Ops, algo deu errado!',
      description: 'Empresa já cadastrada.',
      button: 'Fechar',
    },
    [ErrorsEnum.BATCH_STATUS_ERROR]: {
      title: 'Ops, algo deu errado!',
      description: 'Não foi possível atualizar o status dos lotes.',
      button: 'Fechar',
    },
    error_boundary: {
      title: 'Algo deu errado!',
      support: 'Estamos enfrentando dificuldades técnicas.',
      description:
        'Por favor, volte ao login, entre na sua conta novamente e refaça o processo mais uma vez. Se o problema persistir, entre em contato com o suporte.',
      button: {
        understood: 'Entendi',
      },
    },
  },
};
