import { RechargeSteps } from '../../types';
import { NewRechargeSteps } from '../types';
import { useRechargeSend } from './send.hook';
import { RechargeSendLayout } from './send.layout';

interface IRechargeSend {
  file: File | null;
  onChangeFile: (file: File) => void;
  onChangeNewRechargeStep: (step: NewRechargeSteps) => void;
  onChangeRechargeStep: (step: RechargeSteps) => void;
}

export const RechargeSend = ({
  onChangeRechargeStep,
  onChangeNewRechargeStep,
  file,
  onChangeFile,
}: IRechargeSend) => {
  const props = useRechargeSend({
    onChangeFile,
  });

  return (
    <RechargeSendLayout
      file={file}
      onChangeNewRechargeStep={onChangeNewRechargeStep}
      onChangeRechargeStep={onChangeRechargeStep}
      {...props}
    />
  );
};
