export enum BatchProposalColumns {
  PERSON_FULL_NAME = 'person_full_name',
  PREFERRED_NAME = 'preferred_name',
  GENDER = 'gender',
  MARITAL_STATUS = 'marital_status',
  EMANCIPATED_MINOR = 'emancipated_minor',
  EMAIL = 'email',
  CPF = 'cpf',
  MOTHER_NAME = 'mother_name',
  BIRTH_DATE = 'birth_date',
  PHONE_DIALING_CODE = 'phone_dialling_code',
  PHONE_AREA_CODE = 'phone_area_code',
  PHONE_NUMBER = 'phone_number',
  ADDRESS_POSTAL_CODE = 'address_postal_code',
  ADDRESS_STREET = 'address_street',
  ADDRESS_NUMBER = 'address_number',
  ADDRESS_COMPLEMENT = 'address_complement',
  ADDRESS_NEIGHBORHOOD = 'address_neighborhood',
  ADDRESS_CITY = 'address_city',
  ADDRESS_ADMINISTRATIVE_AREA_CODE = 'address_administrative_area_code',
  ADDRESS_COUNTRY_CODE = 'address_country_code',
}

export enum BatchProposalColumnsTranslated {
  PESSOA_NOME_COMPLETO = 'pessoa_nome_completo*',
  NOME_PREFERIDO = 'nome_preferido',
  SEXO = 'sexo',
  ESTADO_CIVIL = 'estado_civil',
  EMANCIPADO_MENOR = 'emancipado_menor',
  EMAIL = 'email*',
  CPF = 'cpf*',
  NOME_DA_MÃE = 'nome_da_mae*',
  DATA_NASCIMENTO = 'data_nascimento*',
  CÓDIGO_DE_DISCAGEM_TELEFONE = 'codigo_de_discagem_telefone*',
  CÓDIGO_DE_ÁREA_TELEFONE = 'codigo_de_area_telefone*',
  NÚMERO_TELEFONE = 'numero_telefone*',
  CÓDIGO_POSTAL_ENDEREÇO = 'codigo_postal_endereco*',
  RUA_ENDEREÇO = 'rua_endereco*',
  NÚMERO_ENDEREÇO = 'numero_endereco*',
  COMPLEMENTO_ENDEREÇO = 'complemento_endereco',
  BAIRRO_ENDEREÇO = 'bairro_endereco*',
  CIDADE_ENDEREÇO = 'cidade_endereco*',
  CÓDIGO_ÁREA_ADMINISTRATIVA_ENDEREÇO = 'codigo_area_administrativa_endereco*',
  CÓDIGO_PAÍS_ENDEREÇO = 'codigo_pais_endereco*',
}
