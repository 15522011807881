import { useDispatch } from 'react-redux';

import { ErrorsEnum } from '~/api/constants';
import { useLoadOrderServiceOrderControllerValidateOrder } from '~/api/queries';
import { ApiError, CreateOrderBodyDto, OrderDetails } from '~/api/requests';
import { setError } from '~/redux/reducers/application';

import { FileErrors } from '../new-recharge.hook';
import { NewRechargeSteps } from '../types';

interface IUseRechargeValidation {
  handleValidateFile: () => void;
  isValidationLoading: boolean;
}

interface IUseRechargeValidationProps {
  onChangeNewRechargeStep: (step: NewRechargeSteps) => void;
  onChangeFileErrors: (errors: FileErrors) => void;
  onChangeFileData: (data: OrderDetails) => void;
  file: File | null;
}

export const useRechargeValidation = ({
  onChangeNewRechargeStep,
  onChangeFileErrors,
  onChangeFileData,
  file,
}: IUseRechargeValidationProps): IUseRechargeValidation => {
  const dispatch = useDispatch();

  const validateOrder = useLoadOrderServiceOrderControllerValidateOrder({
    onSuccess: (response) => {
      onChangeFileErrors([]);
      onChangeFileData(response);
      onChangeNewRechargeStep(NewRechargeSteps.PAYMENT_METHOD);
    },
    onError: (error: ApiError) => {
      if (
        error.body.code === ErrorsEnum.LOAD_ORDER_CPF_NOT_FOUND ||
        error.body.code === ErrorsEnum.LOAD_ORDER_DUPLICATED_CPF ||
        error.body.code === ErrorsEnum.LOAD_ORDER_INVALID_VALUES
      ) {
        onChangeFileErrors(error.body.details);
      } else {
        dispatch(setError(error.body.code));
      }
    },
  });

  const handleValidateFile = async () => {
    validateOrder.mutate({ formData: { file: file } as unknown as CreateOrderBodyDto });
  };

  return {
    handleValidateFile,
    isValidationLoading: validateOrder.isPending,
  };
};
