import { Button as BaseButton, ButtonSizes, ButtonTypes } from '@vizir-banking/design-system/web';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 32px;
`;

export const Title = styled.h1`
  font-size: 36px;
`;

export const Support = styled.h1`
  font-size: 20px;
`;

export const Description = styled.p`
  font-size: 16px;
  margin-top: 24px;
`;

export const Button = styled(BaseButton).attrs({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
})``;
