import React, { ReactElement } from 'react';

import { OrderDetails } from '~/api/requests';

import { PaymentSelectedOptions } from '../payment-type/types';
import { NewRechargeSteps } from '../types';
import { useRechargeDistribution } from './distribution.hook';
import { RechargeDistributionLayout } from './distribution.layout';
import { DistributionOptions } from './types';

interface IRechargeDistribution {
  fileData: OrderDetails;
  paymentTypes: PaymentSelectedOptions;
  totalValue: number;
  creditBalance: number;
  onChangeNewRechargeStep: (step: NewRechargeSteps) => void;
  handleCreateOrder: () => Promise<void>;
  isOrderLoading: boolean;
  creationSuccess: boolean;
  distributionType: DistributionOptions | null;
  onChangeDistributionType: (type: DistributionOptions) => void;
  selectedDate: Date | null;
  onChangeSelectedDate: (date: Date | null) => void;
}

export const RechargeDistribution = ({
  fileData,
  paymentTypes,
  totalValue,
  creditBalance,
  onChangeNewRechargeStep,
  handleCreateOrder,
  distributionType,
  onChangeDistributionType,
  selectedDate,
  onChangeSelectedDate,
  creationSuccess,
  isOrderLoading,
}: IRechargeDistribution): ReactElement => {
  const props = useRechargeDistribution({
    totalValue,
    creditBalance,
    distributionType,
    onChangeDistributionType,
    selectedDate,
    onChangeSelectedDate,
  });
  return (
    <RechargeDistributionLayout
      fileData={fileData}
      paymentTypes={paymentTypes}
      onChangeNewRechargeStep={onChangeNewRechargeStep}
      handleCreateOrder={handleCreateOrder}
      creationSuccess={creationSuccess}
      isOrderLoading={isOrderLoading}
      {...props}
    />
  );
};
