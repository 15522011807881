import { IconographyNames } from '@vizir-banking/design-system';
import { Icon, Typography, TypographyVariants } from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

const BaseIcon = styled(Icon).attrs({
  color: getColor('neutral', 'lightest'),
})`
  width: 12px;
  height: 12px;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`;

export const Divider = styled.div<{ isActive: boolean }>`
  height: 3px;
  width: 100%;
  background-color: ${({ isActive }) =>
    isActive ? getColor('success', 'medium') : getColor('neutral', 'medium')};
  flex: 1;
`;

export const Dot = styled.div<{ isActive: boolean; hasError?: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 500px;
  background-color: ${({ isActive, hasError }) =>
    isActive
      ? getColor('success', 'medium')
      : hasError
      ? getColor('error', 'medium')
      : getColor('neutral', 'lightest')};
  border: 1px solid ${({ isActive, hasError }) => !(isActive || hasError) && getColor('neutral', 'medium')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DotLabel = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_XS,
})<{ isActive: boolean; hasError?: boolean }>`
  color: ${({ isActive, hasError }) =>
    isActive
      ? getColor('success', 'medium')
      : hasError
      ? getColor('error', 'medium')
      : getColor('neutral', 'darkest')};
`;

export const CheckIcon = styled(BaseIcon).attrs({
  iconName: IconographyNames.check,
})``;

export const FailIcon = styled(BaseIcon).attrs({
  iconName: IconographyNames.close,
})``;
