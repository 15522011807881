import { IconographyNames } from '@vizir-banking/design-system';
import { light, regular } from '@vizir-banking/design-system/src/fonts/fonts';
import {
  Banner,
  BannerTypes,
  Button as BaseButton,
  Button,
  ButtonSizes,
  ButtonTypes,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import BaseDatePicker from 'react-datepicker';
import styled from 'styled-components';

import { designSystemTheme } from '~/styles/theme/design-system-theme';
import { getColor } from '~/styles/theme/get-theme';

export const SummaryContainer = styled.div`
  width: 100%;
  padding: 16px 32px 24px;
  border: 1px solid ${getColor('neutral', 'light')};
  border-radius: 8px;
  margin: 32px 0 24px;
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 16px;
`;

export const SummaryTitle = styled(Typography).attrs({
  variant: TypographyVariants.SUBTITLE,
})`
  font-weight: 700;
  color: ${getColor('neutral', 'darkest')};
  margin: 8px 0;
`;

export const Title = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_L,
})`
  margin-bottom: 24px;
  color: ${getColor('neutral', 'darkest')};
`;

export const Label = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_S,
})`
  color: ${getColor('neutral', 'darker')};
  margin-bottom: 24px;
`;

export const OptionWrapper = styled.div<{ isActive: boolean }>`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
  padding: 16px 24px;
  border: 2px solid
    ${({ isActive }) => (isActive ? getColor('primary', 'dark') : getColor('neutral', 'dark'))};
  border-radius: 8px;
  cursor: pointer;
`;

export const OptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  .react-datepicker__tab-loop {
    display: initial;
  }
  .react-datepicker-popper {
    display: initial;
  }
  .react-datepicker__tab-loop__end {
    display: initial;
  }
  .react-datepicker__tab-loop__start {
    display: initial;
  }
  .react-datepicker__header {
    display: flex;
    background-color: ${designSystemTheme.designSystem.palette.neutral.lightest};
    border-bottom: 1px solid ${designSystemTheme.designSystem.palette.neutral.light};
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
  .react-datepicker {
    font-family: ${designSystemTheme.designSystem.typography.fonts.primary.normal[light]};
  }
  .react-datepicker__month-container {
    display: initial;
    width: 250px;
    justify-content: center;
    align-items: center;
  }
  .react-datepicker__month {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .react-datepicker__week-container {
    display: initial;
  }
`;

export const OptionTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const OptionTitle = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})<{ isDisabled?: boolean }>`
  color: ${({ isDisabled }) => (isDisabled ? getColor('neutral', 'medium') : getColor('neutral', 'darkest'))};
`;

export const DateWarning = styled(Banner).attrs({
  type: BannerTypes.info,
})`
  border: none;
  flex-direction: row;
  padding: 0;
  gap: 8px;
  align-items: center;
  color: ${getColor('neutral', 'darkest')};
  font-family: ${designSystemTheme.designSystem.typography.fonts.primary.normal[regular]};

  path {
    fill: ${getColor('neutral', 'darkest')} !important;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
`;

export const PrimaryButton = styled(BaseButton).attrs(() => ({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
  withTrailingIcon: true,
  trailingIconName: IconographyNames.chevronRight,
  trailingIconColor: getColor('neutral', 'darkest'),
}))``;

export const TertiaryButton = styled(BaseButton).attrs(() => ({
  buttonType: ButtonTypes.tertiary,
  buttonSize: ButtonSizes.medium,
}))`
  color: ${getColor('neutral', 'dark')};
`;

export const Value = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})`
  color: ${getColor('neutral', 'darkest')};
  text-align: left;
  width: 100%;
`;

export const ValueBold = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XL,
})`
  color: ${getColor('primary', 'regular')};
  font-weight: 700;
  text-align: left;
  width: 100%;
`;

export const TotalValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  gap: 4px;
`;

export const DatePickerWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 24px;
`;

export const DatePicker = styled(BaseDatePicker)``;

export const DatePickerInput = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border: 1px solid ${getColor('neutral', 'dark')};
  border-radius: 4px;
  cursor: pointer;
  background-color: ${getColor('neutral', 'lightest')};
  font-size: ${designSystemTheme.designSystem.typography.fontSize.l};
  font-family: ${designSystemTheme.designSystem.typography.fonts.primary.normal[regular]};
`;

export const DatePickerYear = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_S,
})`
  margin: 0 16px;
  color: ${getColor('primary', 'regular')};
`;

export const DatePickerCustomSelect = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: Arial;
`;

export const DatePickerSelect = styled.select`
  display: none;
`;

export const DatePickerSelectSelected = styled.div`
  background-color: transparent;
  color: ${getColor('primary', 'regular')};
  padding: 8px 16px;
  border: 1px solid transparent;
  cursor: pointer;
  position: relative;
  width: 130px;
  justify-content: space-between;

  &:after {
    content: '';
    position: absolute;
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: ${getColor('primary', 'regular')} transparent transparent transparent;
  }

  &.select-arrow-active:after {
    border-color: transparent transparent ${getColor('primary', 'regular')} transparent;
    top: 7px;
  }
`;

export const DatePickerSelectItems = styled.div<{ show: boolean }>`
  position: absolute;
  background-color: ${getColor('neutral', 'lightest')};
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

export const DatePickerSelectItem = styled.div`
  color: ${getColor('primary', 'regular')};
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;

  &:hover,
  &.same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const DatePickerMonth = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_S,
})`
  color: ${getColor('primary', 'regular')};
`;

export const DatePickerHeaderWrapper = styled.div`
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChangeMonthButton = styled(Button).attrs(({ isLeft }: { isLeft?: boolean }) => ({
  buttonType: ButtonTypes.tertiary,
  buttonSize: ButtonSizes.medium,
  withLeadingIcon: true,
  leadingIconName: isLeft ? IconographyNames.chevronLeft : IconographyNames.chevronRight,
  leadingIconColor: getColor('neutral', 'dark'),
}))`
  color: ${getColor('neutral', 'dark')};
`;

export const DayContentWrapper = styled.div<{ isDisabled?: boolean }>`
  background-color: ${getColor('neutral', 'lightest')};
  align-items: center;
  justify-content: center;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
`;

export const DayContent = styled.div<{ isActive?: boolean; isDisabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  color: ${({ isActive, isDisabled }) =>
    isDisabled
      ? getColor('neutral', 'medium')
      : isActive
      ? getColor('neutral', 'lightest')
      : getColor('neutral', 'darkest')};
  border-radius: 50%;
  background-color: ${({ isActive }) =>
    isActive ? getColor('primary', 'regular') : getColor('neutral', 'lightest')};
`;
