import {
  Banner as BaseBanner,
  BannerTypes,
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  Icon as BaseIcon,
  Link as BaseLink,
  LinkVariants,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

export const FileContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  background-color: ${getColor('neutral', 'lighter')};
  border: 2px solid ${getColor('neutral', 'light')};
  border-radius: 8px;
  padding: 16px 24px;
`;

export const FileWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const Icon = styled(BaseIcon).attrs({
  color: getColor('neutral', 'darkest'),
})`
  width: 24px;
  height: 24px;
`;

export const Paragraph = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_XS,
})`
  color: ${getColor('neutral', 'darker')};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
`;

export const Dialog = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})`
  color: ${getColor('neutral', 'darkest')};
  display: inline;
`;

export const Link = styled(BaseLink).attrs({
  linkVariant: LinkVariants.s,
})`
  color: ${getColor('error', 'medium')};
  text-decoration: none;
`;

export const ErrorIcon = styled(BaseIcon).attrs({
  color: getColor('error', 'medium'),
})`
  width: 24px;
  height: 24px;
`;

export const Banner = styled(BaseBanner).attrs({
  type: BannerTypes.info,
})`
  margin: 24px 0px 0px;
`;

export const ButtonWrapper = styled.div`
  flex: 1;
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

export const PrimaryButton = styled(BaseButton).attrs(() => ({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
  leadingIconColor: getColor('neutral', 'darkest'),
  trailingIconColor: getColor('neutral', 'darkest'),
}))``;
